import { extendObservable } from 'mobx';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import MaterialModel from '../models/MaterialModel';
import MaterialAPI from '../services/MaterialAPI';

class MaterialStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      material: undefined,
      materiais: [],
      materiaisListSelect: [],
      staffs: [],
      loading: false,
      superAdmin: false,
      totalPages: 0,
      size: 10,
      page: 0,
      sort: 'code',
    });
  }

  reset() {
    this.material = undefined;
  }

  /**Atualiza uma propriedade do novo adminstrator*/
  async onChange(prop, value) {
    const _material = this.material ? this.material : new MaterialModel();
    switch (prop) {
      default:
        _material[prop] = value;
    }
    this.material = new MaterialModel(_material);
  }

  /**Cria um novo usuário adminin */
  async onCreate() {
    this.loading = true;
    const data = JSON.stringify(this.material);
    const response = await MaterialAPI.save(data);
    this.loading = false;
    if (!response.error) {
      this.material = new MaterialModel();
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Material criado com sucesso.');
    } else  {
      if (response.error.includes('C�digo j� existente!')) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, 'Código já existente');
      } else {
        this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      }
    }
    return response;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 10, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = 'code';

    sort ? (this.sort = sort) : (this.sort = 'code');
    await this.getList();
    return true;
  }

  /**Busca todos os usuarios addmins */
  async getList(size = 10, page = this.page, sort = this.sort) {
    this.loading = true;
    const response = await MaterialAPI.getList({
      size,
      page,
      sort,
    });
    this.loading = false;
    if (response.error) return [];
    this.materiais = response.content.map((usr) => new MaterialModel(usr));
    this.totalPages = response.totalPages;
    this.page = response.number;
  }


  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters,
  ) {

    const response = await MaterialAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
      return response.content.map((usr) => new MaterialModel(usr));
  }


  /**
   * Busca todas as informações de um usuario administrador.
   * @param  {string} userName
   */
  async get(userName) {
    this.loading = true;
    const response = await MaterialAPI.get(userName);
    this.loading = false;
    if (!response.error) {
      this.material = new MaterialModel(response);
      return this.material;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Busca um usuário customer por um parametro. */
  async onUpdate() {
    this.loading = true;

    const data = JSON.stringify(this.material);
    const response = await MaterialAPI.update(this.material.uuid, data);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Informações atualizadas com sucesso!'
      );
      this.material = new MaterialModel(response);
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  /**Deleta administrador */
  async delete(uuid) {
    this.loading = true;
    const response = await MaterialAPI.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Material excluído com sucesso!');
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, this.sort);
  }

  async getMaterialListSelect() {
    const response = await MaterialAPI.getList({
      size: 100,
      page: 0,
      sort: 'code',
    });

    if (response.error) {
      this.materiaisListSelect = [];
      return;
    }

    this.materiaisListSelect = response.content.map((color) => ({
      value: color.uuid,
      label: color.code,
    }));
  }

}
export default MaterialStore;
