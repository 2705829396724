import { action, decorate, extendObservable } from 'mobx';

import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import { currencyToNumber } from '~/helpers/utils/Functions';
import OurStoreModel from '~/models/OurStoreModel';

import OurStoresAPI from '../services/OurStoresAPI';
import FileModel from '~/models/FileModel';
import UploadAPI from '../services/UploadAPI';

class OurStoresStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    // const merchant = this.rootStore.usersStore.userMerchant;

    extendObservable(this, {
      ourStoresList: [],
      ourStoresListSelect: [],
      ourStore: undefined,
      ourStoreFile: undefined,
      sendOurStore: undefined,
      totalPages: 0,
      size: 10,
      page: 0,
      sort: 'created,desc',
      filters: {},
      loading: false,
    });
  }

  reset() {
    this.ourStoresList = [];
    this.ourStoresListSelect = [];
    this.ourStoreFile = undefined;
    this.ourStore = undefined;
    this.totalPages = 0;
    this.size = 10;
    this.page = 0;
    this.sort = 'created,desc';
    this.loading = false;
  }

  initializeOurStore() {
    const merchant = this.rootStore.usersStore.userMerchant;
    this.ourStore = new OurStoreModel({ merchant });
  }

  async getOurStoresListSelect() {
    const response = await OurStoresAPI.getList({
      size: 100,
      page: 0,
      sort: 'name',
    });

    if (response.error) {
      this.ourStoresListSelect = [];
      return;
    }

    this.ourStoresListSelect = response.content.map((ourStore) => ({
      value: ourStore.uuid,
      label: ourStore.name,
    }));
  }

  async getList(
    size = 10,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;
    const response = await OurStoresAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });
    this.loading = false;

    if (response.error) {
      this.ourStoresList = [];
      return;
    }

    this.ourStoresList = response.content.map(
      (ourStore) => new OurStoreModel(ourStore)
    );
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.size = response.size;
  }

  async setPage(page) {
    await this.getList(this.size, page);
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, sort);
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }

  getFileData() {
    return new FileModel({
      file: this.ourStoreFile[0],
      metaTags: ['ourStore'],
      name: this.ourStoreFile[0].name,
    });
  }

  async save() {
    this.loading = true;
    const category = this.ourStore?.category;

    if (category) {
      this.sendOurStore = {
        ...this.ourStore,
        category: {
          uuid: category.value || category.uuid,
        },
      };
    } else {
      this.sendOurStore = {
        ...this.ourStore,
      };
    }

    const response = await OurStoresAPI.save({
      ...this.sendOurStore,
      // merchant
    });
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return response;
    }

    if (this.ourStoreFile && this.ourStoreFile.length) {
      await UploadAPI.uploadOurStore(response.uuid, this.getFileData());
    }

    this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Salvo com sucesso!');
    return response;
  }

  async update() {
    // const merchant = this.rootStore.usersStore.userMerchant;
    const category = this.ourStore?.category;

    if (category) {
      this.sendOurStore = {
        ...this.ourStore,
        category: {
          uuid: category.value || category.uuid,
        },
      };
    } else {
      this.sendOurStore = {
        ...this.ourStore,
      };
    }

    this.loading = true;
    const response = await OurStoresAPI.update(this.ourStore.uuid, {
      ...this.sendOurStore,
      // merchant
    });
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return response;
    }

    if (this.ourStoreFile && this.ourStoreFile.length) {
      await UploadAPI.uploadOurStore(response.uuid, this.getFileData());
    }

    this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Atualizado com sucesso!');
    return response;
  }

  async get(uuid) {
    this.loading = true;
    const response = await OurStoresAPI.get(uuid);
    this.loading = false;
    this.ourStore = new OurStoreModel(response);
  }

  async delete(uuid) {
    this.loading = true;
    const response = await OurStoresAPI.delete(uuid);
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Excluído com sucesso!');
    }

    return response;
  }

  setHoursProp(prop, value) {
    const key = prop.split('_')[0];
    const isOpen = prop.includes('start');

    const oldHour = this.ourStore[key]
      ? this.ourStore[key].split('as')[isOpen ? 1 : 0].replace(/\s/g, '')
      : '';
    const newHour = `${isOpen ? value : oldHour} as ${
      isOpen ? oldHour : value
    }`;

    if (key === 'sundayOpeningHours')
      this.ourStore.holidayOpeningHours = newHour;
    if (key === 'openingHours') this.ourStore.businessHours = newHour;

    this.ourStore[key] = newHour;
  }

  onChange(prop, value) {
    switch (prop) {
      case 'file':
        this.ourStoreFile = value;
        break;
      case 'branch':
        this.ourStore.branch = { uuid: value.value };
        break;
      case 'cep':
        const cep = currencyToNumber(value.replace('-', ''));
        cep > 0 ? (this.ourStore[prop] = cep) : (this.ourStore[prop] = 0);
        break;
      case 'latitude':
      case 'longitude':
        const number = parseFloat(value);
        number > 0
          ? (this.ourStore[prop] = number)
          : (this.ourStore[prop] = 0.0);
        break;
      case 'openingHours_start':
      case 'openingHours_end':
      case 'saturdayOpeningHours_start':
      case 'saturdayOpeningHours_end':
      case 'sundayOpeningHours_start':
      case 'sundayOpeningHours_end':
        this.setHoursProp(prop, value);
        break;
      default:
        this.ourStore[prop] = value;
    }
  }
}

export default OurStoresStore;

