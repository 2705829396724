import React from 'react';
import BaseModel from './BaseModel';

export default class ProductReviewModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.name = data.name;
    this.qty = data.qty;
    this.image = data.image ? data.image : "../../assets/images/sem_imagem.png"
    this.rating = data.rating;
  }
}


