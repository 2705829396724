import BaseAPI, { URLS } from './BaseAPI';

const defaultBodyRequest = {
  '@class': 'br.com.stoom.tresCoracoes.model.dto.TresCoracoesComponentInputDTO',
};
class DynamicHomeAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(`${URLS.DYNAMIC_HOME}/search`, params);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar a lista de Home ' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar a lista de Home ' };
    }
  }

  static async getListChannel(channelUuid, params) {
    try {
      const response = await BaseAPI.get(
        `${URLS.DYNAMIC_HOME}/filter?channelUuid=${channelUuid}`,
        params
      );
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar a lista de Home ' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar a lista de Home ' };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.DYNAMIC_HOME, {
        ...defaultBodyRequest,
        ...data,
      });
      return response.status === 201 || response.status === 200
        ? response.data
        : { error: 'Falha ao salvar Home ' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao salvar Home ' };
    }
  }

  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.DYNAMIC_HOME}/${uuid}`, {
        ...defaultBodyRequest,
        ...data,
      });
      return response.status === 200
        ? response.data
        : { error: 'Falha ao atualizar Home ' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao atualizar Home ' };
    }
  }

  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.DYNAMIC_HOME}/${uuid}`);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar Home ' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar Home ' };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.DYNAMIC_HOME}/${uuid}`, {
        ...defaultBodyRequest,
      });
      return response.status === 200 || response.status === 204
        ? response.data
        : { error: 'Falha ao excluir Home ' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao excluir Home ' };
    }
  }
}

export default DynamicHomeAPI;
