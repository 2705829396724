const PaymentPermissionEnum = Object.freeze({
  POST: {
    pattern: '/manager/payment-setup',
    action: 'POST',
  },
  PUT: {
    pattern: '/manager/payment-setup/**',
    action: 'PUT',
  },
  DELETE: {
    pattern: '/manager/payment-setup/**',
    action: 'DELETE',
  },
  GET: {
    pattern: '/manager/payment-setup',
    action: 'GET',
  },
});

export default PaymentPermissionEnum;
