import BaseModel from './BaseModel';

export default class StockModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.active = data.active;
    this.committedStock = data.committedStock;
    this.created = data.created;
    this.deleted = data.deleted;
    this.externalId = data.externalId;
    this.modified = data.modified;
    this.openSale = data.openSale;
    this.securityStock = data.securityStock;
    this.total = data.total;
    this.uuid = data.uuid;
    this.unit = data.unit;
    this.center = data.center;
  }
}