export const BannerTagEnum = Object.freeze({
  SLIDESHOW_PRINCIPAL: 'SLIDESHOW_PRINCIPAL',
  SLIDESHOW: 'SLIDESHOW',
  IMAGENS_HOME: 'IMAGENS_HOME',
  // CARRINHO: 'CARRINHO',
  // VITRINE_PLP: 'VITRINE_PLP',
  // CHECKOUT: 'CHECKOUT',
  // SUB_HEADER: 'SUB_HEADER',
  BLOG: 'BLOG',
  MARCAS: 'MARCAS',
  // VITRINE_PLP_MARCA: 'VITRINE_PLP_MARCA',
  // VITRINE_PLP_CAMPANHA: 'VITRINE_PLP_CAMPANHA',
  // BANNER_PARCEIROS: 'BANNER_PARCEIROS',
  CATEGORY_BANNER: 'CATEGORY_BANNER',
  CATEGORY_CAROUSEL: 'CATEGORY_CAROUSEL',
  //  BANNER_DIREITA_IMG: 'BANNER_DIREITA_IMG',
  // BANNER_ESQUERDA_IMG: 'BANNER_ESQUERDA_IMG',
  // BANNER_ESQUERDA_TEXTO: 'BANNER_ESQUERDA_TEXTO',
  // BANNER_DIREITA_TEXTO: 'BANNER_DIREITA_TEXTO',
  // BANNER_GRID: 'BANNER_GRID',
  BANNER_FULL: 'BANNER_FULL',
  // INFORMATION_TEXT: 'INFORMATION_TEXT',
 });
