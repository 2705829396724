import BaseModel from './BaseModel';

class InstitutionalModel {
  constructor(data = {}) {
    this.uuid = data.uuid;
    this.name = data.name !== undefined ? data.name : '';
    this.pageType = data.pageType;
    this.ordination = data.ordination !== undefined ? data.ordination : '';
    this.topic = data.topic !== undefined ? data.topic : '';
    this.externalLink =
      data.externalLink !== undefined ? data.externalLink : '';
    this.position = data.position !== undefined ? data.position : '';
    this.content = data.content !== undefined ? data.content : [];
    this.type = 'FOOTER_2';
  }
}

export default InstitutionalModel;
