import CustomerType from './CustomerTypeModel';
import BaseModel from './BaseModel';

export default class PriceModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.price = data.price;
    this.externalId = data.externalId != undefined ? data.externalId : "";
    this.minimumPrice = data.minimumPrice;
    this.initialAmount = data.initialAmount;
    this.finalAmount = data.finalAmount;
    this.customerType = new CustomerType(data.customerType);
    this.unitMeasurement = data.unitMeasurement;
  }
}
