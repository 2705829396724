import BaseAPI, { URLS } from './BaseAPI';

class SubChannelAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(`${URLS.SUBCHANNELS}/search`, params);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar a lista de sub canais' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar a lista de sub canais' };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.SUBCHANNELS, data);
      return response.status === 201
        ? response.data
        : { error: 'Falha ao salvar sub sub canal' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar sub sub canal',
      };
    }
  }

  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.SUBCHANNELS}/${uuid}`, data);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao atualizar sub sub canal' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar sub sub canal',
      };
    }
  }

  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.SUBCHANNELS}/${uuid}`);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar sub sub canal' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar sub sub canal' };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.SUBCHANNELS}/${uuid}`);
      return response.status === 204
        ? response.data
        : { error: 'Falha ao excluir sub sub canal' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao excluir sub sub canal' };
    }
  }
}

export default SubChannelAPI;
