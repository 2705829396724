const InstitucionalPermissionEnum = Object.freeze({
  GET: {
    pattern: '/manager/institutional/search/**',
    action: 'GET',
  },
  GET_UUID: {
    pattern: '/manager/institutional/**',
    action: 'GET',
  },
  DELETE: {
    pattern: '/manager/institutional/**',
    action: 'DELETE',
  },
  POST: {
    pattern: '/manager/institutional',
    action: 'POST',
  },
  PUT: {
    pattern: '/manager/institutional/**',
    action: 'PUT',
  },
});

export default InstitucionalPermissionEnum;
