import { extendObservable, values } from 'mobx';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import PropertiesAPI from '../services/PropertiesAPI';
import PropertiesModel from '../models/PropertiesModel';

class PropertiesStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      propertie: [],
      loading: false,
      superAdmin: false,
      properties: [],
      totalPages: 0,
      propertyAddList: [],
      propertyRemoveList: [],
      propertieSelected: [],
      size: 10,
      number: 0,
      page: 0,
      sort: 'ordering',
    });
  }

  reset() {
    this.propertie = [];
    this.propertyRemoveList = [];
    this.propertyAddList = [];
    this.totalPages = 0;
    this.size = 10;
    this.number = 0;
    this.sort = 'ordering';
    this.loading = false;
  }

  /**Atualiza uma propriedade da nova unidade */
  async onChange(prop, value) {
    const _propertie = this.propertie ? this.propertie : new PropertiesModel();
    switch (prop) {
      default:
        _propertie[prop] = value;
    }

    this.propertie = new PropertiesModel(_propertie);
  }

  /**Cria uma propriedade nova */
  async onCreate() {
    this.loading = true;
    this.propertie.merchant.uuid = '86fb8770-0a56-48ea-a01f-52be88d3a7b9';
    const data = JSON.stringify(this.propertie);
    const response = await PropertiesAPI.save(data);

    this.loading = false;
    if (!response.error) {
      this.propertie = new PropertiesModel();
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Especificação criada com sucesso.'
      );

      if (this.propertyAddList.length > 0) {
        this.propertyAddList.map(async (item) => {
          await PropertiesAPI.saveProperties(response.uuid, [item]);
        });
      }
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Busca todas as propriedades */
  async getList(size = 10, page = this.page, sort = this.sort) {
    this.loading = true;
    const response = await PropertiesAPI.getList({
      size,
      page,
      sort,
    });
    this.loading = false;
    if (response.error) return [];
    this.properties = response.content.map(
      (properties) => new PropertiesModel(properties)
    );
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    const response = await PropertiesAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content.map((usr) => new PropertiesModel(usr));
  }

  /**
   * Busca todas as informações da propriedade.
   * @param  {string} uuid
   */
  async get(uuid) {
    this.loading = true;
    const response = await PropertiesAPI.get(uuid);
    this.loading = false;
    if (!response.error) {
      this.propertie = new PropertiesModel(response);
      return this.propertie;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
  }

  /**Deleta Tamanho */
  async delete(uuid) {
    this.loading = true;
    const response = await PropertiesAPI.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Especificação excluída com sucesso.'
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Atualiza os dados do tamanho. */
  async onUpdate() {
    this.loading = true;
    const data = JSON.stringify(this.propertie);
    const response = await PropertiesAPI.update(this.propertie.uuid, data);

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Informações atualizadas com sucesso!'
      );

      if (this.propertyAddList.length > 0) {
        this.propertyAddList.map(async (item) => {
          await PropertiesAPI.saveProperties(response.uuid, [item]);
        });
      }

      if (this.propertyRemoveList.length > 0) {
        this.propertyRemoveList.map(async (item) => {
          await PropertiesAPI.deleteProperties(item.uuid);
        });
      }

      this.propertie = new PropertiesModel(response);
      this.propertyRemoveList = [];
      this.propertyAddList = [];
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  addValuePropertiesList(unit) {
    this.propertie = this.propertie ? this.propertie : new PropertiesModel();
    unit.ordering = unit.orderingTable;
    unit.propertyValue = unit.displayString;
    this.propertie.values.push(unit);
    this.propertyAddList.push(unit);
  }

  removeValuePropertiesList(unit) {
    this.loading = true;
    const removeItem = this.propertie.values.filter(
      (item) => item.displayString !== unit.displayString
    );
    if (unit.uuid) {
      this.propertyRemoveList.push(unit);
    }
    this.propertie.values = removeItem;
    this.loading = false;
  }

  async getPropertieListSelect() {
    const response = await PropertiesAPI.getList({
      size: 100,
      page: 0,
      sort: 'ordering',
    });

    if (response.error) {
      this.propertieSelected = [];
      return;
    }

    this.propertieSelected = response.content.map((propertie) => ({
      value: propertie.uuid,
      label: propertie.className,
      item: propertie.values,
    }));
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, this.sort);
  }

  async setPage(page) {
    this.page = page;
    await this.getList();
  }

  async setSortPropertyValues(e) {
    const field = e.split(',')[0];
    const direction = e.split(',')[1];
    let list = this.propertie?.values ?? [];

    if (field === 'ordering') {
      list = this.sortNumericField(
        list,
        field,
        direction || 'asc'
      );
    } else {

      list = this.sortStringField(
        list,
        field,
        direction || 'asc'
      )
    }
    this.propertie.values = list;
  }


  sortNumericField(list, field, direction) {
    const output = list.sort((a, b) => {
      if (direction === 'desc') {
        return b[field] - a[field];
      } else {
        return a[field] - b[field];
      }
    });
    return output;
  }



  sortStringField(list, field, direction) {

    const output = list.sort((a, b) => {
      if (direction === 'desc') {
        return a[field] > b[field] ? -1 : 0;
      } else {
        return b[field] > a[field] ? -1 : 0;
      }
    });

    return output
  }

}

export default PropertiesStore;
