import { extendObservable } from 'mobx';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import ProductComentModel from '~/models/ProductRatingModel';
import ProductReviewModel from '../models/ProductReviewModel';
import ProductAPI from '../services/ProductAPI';

/**Valores inicias de variaveis observadas */
const initValues = {
  loading: false,
  products: [],
  statuses: 'ACCEPTED,PENDING,REJECTED',
  defaultTo: '',
  defaultFrom: '',
  reportRatingList: [],
  size: 15,
  page: 0,
  totalPages: 0,
};
class ProductReviewStore {
  totalPages = 0;
  page = 0;
  size = 15;
  sort = 'rating,desc';
  filter = '';
  loading = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    // this.rootStore.customerTypeStore.getList();
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      ...initValues,
    });
  }

  reset() {
    this.totalPages = 0;
    this.page = 0;
    this.products = [];
    this.size = 15;
    this.sort = 'rating,desc';
    this.filter = '';
    this.loading = false;
  }

  /**Retorna instancia da notificationStore  */
  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  async getFirstFilterDates() {
    const fDate = new Date();
    const tDate = new Date();

    fDate.setDate(fDate.getDate() - 30);
    const fromYear = fDate.getFullYear();
    const fromMonth =
      fDate.getMonth() + 1 >= 10
        ? fDate.getMonth() + 1
        : `0${fDate.getMonth() + 1}`;
    const fromDay =
      fDate.getDate() >= 10 ? fDate.getDate() : `0${fDate.getDate()}`;
    const fromDate = `${fromYear}-${fromMonth}-${fromDay}`;

    const toYear = tDate.getFullYear();
    const toMonth =
      tDate.getMonth() + 1 >= 10
        ? tDate.getMonth() + 1
        : `0${tDate.getMonth() + 1}`;
    const toDay =
      tDate.getDate() >= 10 ? tDate.getDate() : `0${tDate.getDate()}`;
    const toDate = `${toYear}-${toMonth}-${toDay}`;

    this.defaultTo = toDate;
    this.defaultFrom = fromDate;
  }

  handleCommentRating = async (value, el, stateSelected) => {
    this.loading = true;
    const status = value;
    const response = await ProductAPI.updateStatusComment(el, el.uuid, value);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.SUCESS, 'Alteração realizada com sucesso')
      const comment = this.products.find((comment) => comment.uuid === el.uuid);
      comment.status = status;
      this.statuses = 'ACCEPTED,PENDING,REJECTED'
      this.getList();
      this.products = [...this.products];
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  };

  async setSort(sort) {
    this.sort = sort;
    await this.getList();
  }

  async getList(data) {
    this.loading = true;
    this.statuses = data?.statuses ?? this.statuses;

    const params = {
      page: this.page,
      size: this.size,
      sort: this.sort,
      name: this.filter,
      statuses: this.statuses,
      ...data,
    };

    if (params.rating === 0) {
      delete params.rating;
    }

    const response = await ProductAPI.getRatings(params);
    if (!response.error) {
      this.products = await response.content.map(
        (prd) => new ProductComentModel(prd)
      );

      this.totalPages = response.totalPages;
      this.page = response.number;
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
  }

  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters,
    statuses = this.statuses
  ) {
    const response = await ProductAPI.getRatings({
      size,
      page,
      sort,
      statuses,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content.map((usr) => new ProductComentModel(usr));
  }

  async setPage(numPage) {
    this.page = numPage;
    this.getList({ page: this.page });
  }

  async setFiltersGetListReport(filters) {
    this.filters = filters;
    await this.getListReport(this.filters);
  }

  async setPageReports(numPage, size = 15) {
    this.page = numPage;
    this.size = size;
    await this.getListReport(this.filters);
    return true;
  }

  async setSortReport(sort) {
    this.sort = sort;
    await this.getListReport(this.size, this.page, this.sort);
  }

  async getListReport(data) {
    this.loading = true;
    const params = {
      from: this.filters?.from ? this.filters?.from : this.defaultFrom,
      to: this.filters?.to ? this.filters?.to : this.defaultTo,
      productName: this.filters?.productName ? this.filters?.productName : '',
      rating: this.filters?.rating ? this.filters?.rating : '5',
      sort: this.sort,
    };


    const size = 999999;
    const page = this.page;
    const response = await ProductAPI.getRatingsReports(params, size, page);

    if (!response.error) {
      this.reportRatingList = response.content;

      this.reportRatingList = await response.content.map(
        (prd) => new ProductReviewModel(prd)
      );

      this.topViewProducts = response;
      this.totalPages = response.totalPages;
      // this.page = response.number;
    } else {
      // this.toastHelper.notify(STATUS_HELPER.ERROR, response);
    }
    this.loading = false;
  }

  async getExpoxtListReport() {
    const params = {
      from: this.filters?.from ? this.filters?.from : this.defaultFrom,
      to: this.filters?.to ? this.filters?.to : this.defaultTo,
      productName: this.filters?.productName ? this.filters?.productName : '',
      rating: this.filters?.rating ? this.filters?.rating : '5',
      sort: this.sort,
    };

    const size = 99999;

    const response = await ProductAPI.getRatingsReports(params, size, 0);
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response;
  }
}

export default ProductReviewStore;
