import BaseModel from './BaseModel';
import { aliasPaymentOptionName } from '~/helpers/utils/Functions';
import { valueStatusConvert } from '../helpers/utils/Functions';

export default class PaymentDetailsModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this['@class'] = 'br.com.stoom.kernel.model.persistence.PaymentDetails';
    this.valueToBill = data.valueToBill;
    this.valueToRefund = data.valueToRefund;
    this.paymentStatus = valueStatusConvert(data.paymentStatus);
    this.installments = data.installments;
    this.installmentValue = data.installmentValue;
    this.paymentOptionName = data.paymentOptionName;
    this.paymentType = aliasPaymentOptionName(data.paymentOptionName);
    this.installmentValue = data.installmentValue;
  }
}
