import BaseModel from './BaseModel';
import ProductModel from './ProductModel';
import { formatNumbers } from '~/helpers/utils/Functions';

export default class OrderItemsModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this['@class'] = 'br.com.stoom.kernel.model.persistence.OrderItem';
    this.unitPrice = `R$ ${formatNumbers(data.totalDiscounts)}`;
    this.total = `R$ ${formatNumbers(data.totalDiscounts)}`;
    this.totalBilled = `R$ ${formatNumbers(data.totalBilled)}`;
    this.discount = `R$ ${formatNumbers(data.totalDiscounts)}`;
    this.amount = data.amount;
    this.amountBilled = data.amountBilled;
    this.product = data.product ? new ProductModel(data.product) : undefined;
  }
}