import { extendObservable } from 'mobx';
import { MerchantEnum } from '~/helpers/utils/enums/MerchantEnum';
import MerchantModel from '~/models/MerchantModel';
import MerchantAPI from '../services/MerchantAPI';

const initValues = {
  loading: false,
  merchant: undefined,
  merchants: [],
};

/** Store que manipula dados de merchants */
class MerchantStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, { ...initValues });
  }

  /** Atualiza uma propriedade do merchant */
  updateProp(prop, value) {
    const { merchant } = this;
    merchant[prop] = value;
    if (prop === 'entity') {
      merchant.document = '';
    }
    this.merchant = new MerchantModel(merchant);
  }

  createEmptyMerchant() {
    this.merchant = new MerchantModel({});
  }

  getDefaultMerchant() {
    if (this.merchants && this.merchants?.length > 0) {
      return this.merchants.filter(
        (f) => f.name === MerchantEnum.STOOM_MERCHANT
      )[0];
    }

    return null;
  }

  /**Busca apenas um determinado merchant */
  async getMerchant(uuid) {
    this.loading = true;
    const response = await MerchantAPI.getMerchantByUUID(uuid);
    if (!response.error) this.merchant = new MerchantModel(response);
    else this.toastHelper.notify('error', response.error);
    this.loading = false;
  }

  /** Retorna lista de merchants no padrão utilizado pelo select component */
  getListSelect() {
    const merchants = this.merchants.map((mMerchant) => ({
      value: mMerchant.uuid,
      label: mMerchant.name,
    }));
    return merchants;
  }

  /** Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 20) {
    this.page = numPage;
    this.size = size;
    await this.getList();
    return true;
  }

  /** Busca lista de merchants */
  async getList() {
    this.loading = true;
    const params = { page: this.page, size: this.size };
    const response = await MerchantAPI.getList(params);
    this.loading = false;
    if (!response.error) {
      this.merchants = response.content.map(
        (mMerchant) => new MerchantModel(mMerchant)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
      return this.merchants;
    }
    return response;
  }

  /** Salva novo merchant */
  async save() {
    this.loading = true;
    const data = JSON.stringify(this.merchant);
    const response = await MerchantAPI.save(data);
    if (!response.error) this.merchant = new MerchantModel();
    this.loading = false;
    return response;
  }
}

export default MerchantStore;
