import { extendObservable } from 'mobx';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import ConfigStoreModel from '../models/ConfigStore';
import StoreSetupModel from '../models/StoreSetupModel';
import ConfigAPI from '../services/ConfigAPI';


class ConfigStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      loading: false,
      config: new ConfigStoreModel(),
    });
  }

  reset() {
    this.loading = false;
    this.storeSetup = new StoreSetupModel();
  }

  async get() {
    this.loading = true;
    const response = await ConfigAPI.get();
    this.loading = false;
    if (response.error) return [];
    this.config = new ConfigStoreModel(response);
  }

  onChange(prop, value) {
    switch (prop) {
      default:
        this.config[prop] = value;
    }
  }

  async onUpdate() {
    this.loading = true;
    const response = await ConfigAPI.update(this.config);
    this.loading = false;
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, 'Erro na alteração de unidade Geral');
      return [];
    }

    this.toastHelper.notify(
      STATUS_HELPER.SUCCESS,
      'Alteração realizada com sucesso'
    );

    this.config = new ConfigStoreModel(response);
  }
}

export default ConfigStore;
