import { extendObservable } from 'mobx';

import SizeAPI from '../services/SizeAPI';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';

import SizeModel from '~/models/SizeModel';

class SizeStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      size: [],
      sizes: [],
      sizesListSelect: [],
      staffs: [],
      loading: false,
      superAdmin: false,
      totalPages: 0,
      size: 10,
      page: 0,
      sort: 'code',
    });
  }

  reset() {
    this.size = [];
    this.totalPages = 0;
    this.size = 10;
    this.number = 0;
    this.sort = 'code';
    this.loading = false;
  }

  /**Atualiza uma propriedade do novo adminstrator*/
  async onChange(prop, value) {
    const _size = this.size ? this.size : new SizeModel();
    switch (prop) {
      default:
        _size[prop] = value;
    }
    this.size = new SizeModel(_size);
  }

  /**Cria um size novo */
  async onCreate() {
    this.loading = true;
    const data = JSON.stringify(this.size);
    const response = await SizeAPI.save(data);
    this.loading = false;
    if (!response.error) {
      this.size = new SizeModel();
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Tamanho criado com sucesso.');
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, this.sort);
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 10, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = 'code';

    sort ? (this.sort = sort) : (this.sort = 'code');

    await this.getList();
    return true;
  }

  /**Busca todos os usuarios addmins */
  async getList(size = 10, page = this.page, sort = this.sort) {
    this.loading = true;
    const response = await SizeAPI.getList({
      size,
      page,
      sort,
    });
    this.loading = false;
    if (response.error) return [];
    this.sizes = response.content.map((usr) => new SizeModel(usr));
    this.totalPages = response.totalPages;
    this.page = response.number;
  }


  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters,
  ) {

    const response = await SizeAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
      return response.content.map((usr) => new SizeModel(usr));
  }


  /**
   * Busca todas as informações do size.
   * @param  {string} uuid
   */
  async get(uuid) {
    this.loading = true;
    const response = await SizeAPI.get(uuid);
    this.loading = false;
    if (!response.error) {
      this.size = new SizeModel(response);
      return this.size;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Atualiza os dados do tamanho. */
  async onUpdate() {
    this.loading = true;

    const data = JSON.stringify(this.size);
    const response = await SizeAPI.update(this.size.uuid, data);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Informações atualizadas com sucesso!'
      );
      this.size = new SizeModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Deleta Tamanho */
  async delete(uuid) {
    this.loading = true;
    const response = await SizeAPI.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Tamanho excluído com sucesso!'
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async getSizeListSelect() {
    const response = await SizeAPI.getList({
      size: 100,
      page: 0,
      sort: 'code',
    });

    if (response.error) {
      this.sizesListSelect = [];
      return;
    }

    this.sizesListSelect = response.content.map((size) => ({
      value: size.uuid,
      label: size.code,
    }));
  }
}
export default SizeStore;
