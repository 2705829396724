import BaseAPI, { URLS } from './BaseAPI';

class DashboardApi {
  static async getList() {
    try {
      const response = await BaseAPI.put(URLS.DASHBOARD);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar a lista de cores' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar a lista de cores' };
    }
  }
 
  static async getListCategory(params) {
    try {
      const response = await BaseAPI.put(`${URLS.MANAGER}/reports/best-selling-categories`, params);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar a lista de cores' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar a lista de cores' };
    }
  }
 
}

export default DashboardApi;
