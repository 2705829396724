export default class TopBarContentsModel {
  constructor(data = {}) {
    if (!data) return;
    this.uuid = data.uuid;
     this.ordination = data.ordination !== undefined ? data.ordination : '';
     this.name = data.name !== undefined ? data.name : '';
     this.link = data.link !== undefined ? data.link : '';
     this.position = data.position !== undefined ? data.position : '';
     this.mobile = data.mobile;
  }
}
 