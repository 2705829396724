import BaseModel from './BaseModel';
import MerchantModel from './MerchantModel';
class BranchModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;

    // this["@class"] = (data['@class']) ? data['@class'] : "br.com.stoom.partage.model.dto.PartageBranchInputDTO";

    this.merchant = data.merchant && new MerchantModel(data.merchant);
    this.externalId = data.externalId ? data.externalId : new Date().getTime();

    this.uuid = data.uuid;
    this.name = data.name;
    this.document = data.document;
    this.email = data.email;
    this.addressComplement = data.addressComplement;
    this.block = data.block;
    this.city = data.city;
    this.phone = data.phone;
    this.state = data.state;
    this.street = data.street;
    this.zipcode = data.zipcode;

    this.lat = data.lat;
    this.lng = data.lng;

    this.openingHours = data.openingHours;
    this.holidayOpeningHours = data.holidayOpeningHours;
    this.saturdayOpeningHours = data.saturdayOpeningHours;
    this.sundayOpeningHours = data.sundayOpeningHours;

    this.branchImage = data.branchImage;
  }
}

export default BranchModel;
