import axios from 'axios';
import StorageUtil, { KEYS } from '~/helpers/utils/StorageUtil';
import { decodeJWT } from '~/helpers/utils/Functions';

/** Classe base que fará a comunicação com o backend.
 * Esta classe trata as configurações de headers, paths e todas as urls(endpoints) deverão ser inseridos aqui
 */
class BaseAPI {
  /** Métodos get */
  static async get(endpoint, data = {}) {
    return await api.get(endpoint, { params: data });
  }

  /** Métodos post */
  static async post(endpoint, data = {}, config) {
    const requestConfig = { code: config };

    console.log('requestConfig', requestConfig);
    return await api.post(endpoint, data, requestConfig);
  }

  /** Método Put */
  static async put(endpoint, data = {}) {
    return await api.put(endpoint, data);
  }

  /** Método delete */
  static async delete(endpoint, data = {}) {
    return await api.delete(endpoint, { data });
  }

  /** Método Patch */
  static async patch(endpoint, data = {}) {
    return await api.patch(endpoint, data);
  }
}

/** URL de acordo ao protocolo, hostname e endpoint */
export const baseURL = () =>
  process.env.REACT_APP_API_URL ||
  'https://api-stg.3coracoesconecta.com.br';
//export const baseURL = () => 'http://localhost:8080';

// Cria uma instancia de api
const api = axios.create({ baseURL: baseURL(), withCredentials: true });
api.interceptors.request.use(
  (config) => {
    const token = StorageUtil.getItem(KEYS.TOKEN_KEY);
    config.headers = {
      'Content-Type': 'application/json; charset=utf-8',
      accept: '*/*',
    };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (config?.code?.headers?.code) {
      config.headers = { '2fa': config?.code?.headers?.code };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Intercepta os retornos.
// TODO fazer a lógica do refresh token.
api.interceptors.response.use(
  (response) => {
    const token = response.headers.authorization;
    if (token) {
      const currentToken = response.headers.authorization.split(' ')[1];
      const decode = decodeJWT(currentToken);
      StorageUtil.setItem(KEYS.AUTH_KEY, decode.Authorities);
      StorageUtil.setItem(KEYS.TOKEN_KEY, currentToken);
    }
    return response;
  },
  (error) => Promise.reject(error)
);

// Lista de todos os endpoints. Todos endpoints deverão ser inseridos aqui.
const MANAGER = '/manager';
export const URLS = {
  MANAGER,
  LOGIN: '/login',
  FILES: '/files',
  FORGET_PASSWORD: '/forget-password',
  ORDERS: `${MANAGER}/orders`,
  SEARCHORDERS: `${MANAGER}/orders`,
  CUSTOMERS: `${MANAGER}/customers`,
  MERCHANT: `${MANAGER}/merchants`,
  SUPPLIER: `${MANAGER}/suppliers`,
  AUTOMAKER: `${MANAGER}/automakers`,
  SHIPPING: `${MANAGER}/shipping-company`,
  APPLICATION: `${MANAGER}/applications`,
  PRODUCTS: `${MANAGER}/products`,
  CAMPAIGNS: `${MANAGER}/campaigns`,
  CATEGORIES: `${MANAGER}/categories`,
  ACCOUNTS: `${MANAGER}/accounts`,
  PROPERTY: `${MANAGER}/properties`,
  CLASSES: `${MANAGER}/properties/classes`,
  PRE_ORDERS: `${MANAGER}/preorders`,
  INSTITUTIONAL: `${MANAGER}/institutional`,
  ZIPCODERANGE: `${MANAGER}/zipcode-range`,
  CUSTOMER_GROUP: `${MANAGER}/insumosHolambra-customers/types`,
  BANNER: `${MANAGER}/banners`,
  NEWS_LETTER: `${MANAGER}/newsletters`,
  SALES: `${MANAGER}/reports/sales-general`,
  SALES_ABANDONED_CARTS: `${MANAGER}/reports/abandoned-carts`,
  SALES_BEST_SELLING_PRODUCTS: `${MANAGER}/reports/best-selling-products`,
  SALES_BEST_SELLING_CATEGORIES: `${MANAGER}/reports/best-selling-categories`,
  SALES_TOP_VIEW_PRODUCTS: `${MANAGER}/reports/top-view-products`,
  CUPONS_REPORTS: `${MANAGER}/reports/vouchers`,
  CAMPAING_REPORTS: `${MANAGER}/reports/campaigns`,
  REPORT_LOGS: `${MANAGER}/reports/logins`,
  REPORTS_CARD: `${MANAGER}/reports/abandoned-carts-general`,
  SALES_DETAILS: `${MANAGER}/reports/sales-details`,
  BRANCHES: `${MANAGER}/branches`,
  STORES: `${MANAGER}/stores`,
  BANNERS: `${MANAGER}/banners`,
  DYNAMIC_HOME: `${MANAGER}/components`,
  COLORS: `${MANAGER}/colors`,
  CHANNELS: `${MANAGER}/channel`,
  SUBCHANNELS: `${MANAGER}/sub_channel`,
  SIZES: `${MANAGER}/sizes`,
  MATERIAL: `${MANAGER}/material`,
  UNITS: `${MANAGER}/units`,
  VOLTAGE: `${MANAGER}/voltage`,
  PROPERTIES: `${MANAGER}/properties`,
  CUSTOMER_GROUP: `${MANAGER}/customergroups`,
  COUPONS: `${MANAGER}/voucherBatches`,
  ORDER: `${MANAGER}/orders`,
  SHIPPING: `${MANAGER}/shipping`,
  STORE_SETUP: `${MANAGER}/config`,
  STAMP: `${MANAGER}/stamps`,
  INSTITUTIONAL: `${MANAGER}/institutional`,
  DASHBOARD: `${MANAGER}/reports/dashboard`,
  AUTHORITY: `${MANAGER}/authorities`,
  AUTHORITY_GROUPS: `${MANAGER}/authority-groups`,
  TEMPLATE_EMAIL: `${MANAGER}/template-email`,
  PAYMENT_SETUP: `${MANAGER}/payment-setup`,
  TEMPLATE_EMAIL: `${MANAGER}/template-email`,
  AUTH: `${MANAGER}/auth`,
  TRESCORACOESCONFIG: `${MANAGER}/trescoracoesconfig`
};

export default BaseAPI;
