import UserModel from './UserModel';
import AddressModel from './AddressModel';
import { entityTypes } from '~/helpers/utils/Selects';
import {
  validateEmail,
  validateCNPJ,
  validateCPF,
} from '~/helpers/utils/Validators';
import BaseModel from './BaseModel';
import SubChannelModel from './SubChannelModel';
import { valueStatusClient, formatCNPJ } from '~/helpers/utils/Functions';

const convertValue = (value) => {
  const convert = new String(value);
  const split = convert.split('.');

  if (split[1] !== undefined) {
    if (split[1].length === 1) {
      return 'R$ ' + convert.replace('.', ',') + '0';
    } else {
      return 'R$ ' + convert.replace('.', ',');
    }
  } else if (value !== undefined) {
    return 'R$ ' + convert + ',00';
  } else {
    return 'R$ 0,00';
  }
};

class CustomerModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this['@class'] =
      'br.com.stoom.tresCoracoes.model.dto.TresCoracoesCustomerDTO';
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.registration = data.registration;
    this.entity = data.entity || entityTypes[1].value;
    this.corporateName = data.corporateName;
    this.corporateName = data.corporateName;
    this.paymentForm = data.paymentForm;
    this.paymentDeadline = data.paymentDeadline && `${data.paymentDeadline}`;
    this.customerType = data.customerType;
    this.document =
      data.document?.length === 14 ? formatCNPJ(data.document) : data.document;
    this.groupCode = data.groupCode;
    this.emailNf = data.emailNf;
    this.phone = data.phone;
    this.priceTableCode = data.priceTableCode;
    this.subChannel = data.subChannel;
    this.contactName = data.contactName;
    this.businessContact = data.businessContact;
    this.clientUnitsActive = data.clientUnitsActive ?? 'IC';
    this.cellphoneCommercial = data.cellphoneCommercial;
    this.phoneCommercial = data.phoneCommercial;
    this.center = data.center;
    this.tableId = data.tableId;
    this.contactFinancial = data.contactFinancial;
    this.cellphoneFinancial = data.cellphoneFinancial;
    this.phoneFinancial = data.phoneFinancial;
    this.minimumValue = convertValue(data.minimumValue);
    this.limitValue = data.limitValue;
    this.clientGroup = data.clientGroup;
    this.paymentDeadlineCode = data.paymentDeadlineCode;
    this.clientExternal = data.clientExternal;
    this.organizationSales = data.organizationSales;
    this.riskClass = data.riskClass;
    this.seller = data.seller;
    this.subCanal = data.subCanal ? new SubChannelModel(data.subCanal) : null;
    this.email = data.email;
    this.mobile = data.mobile;
    this.mobileWhatsapp = data.mobileWhatsapp;
    this.stateRegistration = data.stateRegistration;
    this.user = new UserModel(data.user);
    this.mainAddress = data.mainAddress
      ? new AddressModel(data.mainAddress)
      : null;
    this.billingAddress = data.billingAddress
      ? new AddressModel(data.billingAddress)
      : null;
    this.shippingClass = data.shippingClass;
    this.availablePLP = data.availablePLP;
    this.externalId = data.externalId;
    this.status = valueStatusClient(data.status);

    if (this.exempt) this.stateRegistration = '';
    this.newsletter = data.newsletter;
  }

  get isValid() {
    if (this.firstName.length < 3) return false;
    if (this.lastName.length < 3) return false;
    if (this.user.password.length < 6) return false;
    if (this.user.username.length < 3) return false;
    if (!validateEmail(this.user.email).isValid) return false;
    if (this.isPJ) {
      if (!validateCNPJ(this.document).isValid) return false;
      if (this.registration.length < 3) return false;
    } else if (!validateCPF(this.document).isValid) return false;
    return true;
  }

  setPJ() {
    this.entity = entityTypes[1].value;
  }

  setPF() {
    this.entity = entityTypes[0].value;
  }

  get currentAddress() {
    return this.addresses.length > 0 ? this.addresses[0] : {};
  }

  get isPJ() {
    return this.entity === entityTypes[1].value;
  }

  /**Retorna o nome completo */
  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  get entityName() {
    return this.entity === entityTypes[1].value
      ? entityTypes[1].label
      : entityTypes[0].label;
  }
}

export default CustomerModel;
