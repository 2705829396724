const VoucherBatchesPermissionEnum = Object.freeze({
  POST: {
    pattern: '/manager/voucherBatches',
    action: 'POST',
  },
  PUT: {
    pattern: '/manager/voucherBatches/**',
    action: 'PUT',
  },
  DELETE: {
    pattern: '/manager/voucherBatches/**',
    action: 'DELETE',
  },
  GET: {
    pattern: '/manager/voucherBatches/search',
    action: 'GET',
  },
  GET_UUID: {
    pattern: '/manager/voucherBatches/**',
    action: 'GET',
  },
});

export default VoucherBatchesPermissionEnum;
