import { extendObservable } from 'mobx';

import API from '../services/OrderAPI';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';

import OrderModel from '~/models/OrderModel';

class OrderStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      order: undefined,
      orders: [],
      ordersCustomer: [],
      channelsListSelect: [],
      staffs: [],
      loading: false,
      superAdmin: false,
      totalPages: 0,
      loadingExport: false,
      totalPagesCustomer: 0,
      size: 15,
      sizeCustomer: 15,
      filters: 'search=status_statusName!CART',
      page: 0,
      pageCustomer: 0,
      sort: 'created,desc',
    });
  }

  reset() {
    this.order = undefined;
    this.totalPages = 0;
    this.size = 15;
    this.number = 0;
    this.sort = 'created,desc';
    this.filters = {};
    this.loading = false;
  }

  /**Atualiza um novo registror*/
  async onChange(prop, value) {
    const _channel = this.order ? this.order : new OrderModel();
    switch (prop) {
      default:
        _channel[prop] = value;
    }
    this.order = new OrderModel(_channel);
  }


  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 15, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = 'created,desc';

    sort ? (this.sort = sort) : (this.sort = 'created,desc');

    await this.getList();
    return true;
  }

  /**Busca todos os registros */
  async getList(size = this.size, page = this.page, sort = this.sort, filters = this.filters) {
    this.loading = true;
    if (filters?.search === undefined) {
      filters.search = 'status_statusName!CART'
    }
    const response = await API.getList({
      size,
      page,
      sort,
      ...filters
    });
    this.loading = false;
    if (response.error) return [];
    this.orders = response.content.map((usr) => new OrderModel(usr));
    this.totalPages = response.totalPages;
    this.page = response.number;
  }


  async getExpoxtList(size, page = 0) {
    this.loadingExport = true;
    try {
      const response = await API.getList(
        {
          size: size,
          sort: this.sort,
          search: this.filter,
          page: page,
        },
      );

      if (response.error) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
        return { items: [], totalElements: 0, totalPages: 0, currentPage: page };
      }

      return {
        items: response.content.map((usr) => new OrderModel(usr)),
        totalElements: response.totalElements,
        totalPages: response.totalPages,
        currentPage: page
      };
    } catch (error) {
      console.error('Erro para buscar pedidos:', error);
      return { items: [], totalElements: 0, totalPages: 0, currentPage: page };
    } finally {
      this.loadingExport = false;
    }
  }

  async fetchAllItems(type) {
    this.loadingExport = true;
    let allItems = [];
    let currentPage = 0;
    const size = 1000; // Ajuste o tamanho do lote conforme necessário
    let totalPages = 1;

    while (currentPage < totalPages) {
      const { items, totalElements, totalPages: pages, currentPage: page } = await this.getExpoxtList(size, currentPage);

      if (items.length > 0) {
        allItems = [...allItems, ...items];
        totalPages = pages;
        currentPage = page + 1;
      } else {
        // Se não há mais itens, saia do loop
        break;
      }
    }

    this.loadingExport = false;
    return allItems.map((usr) => new OrderModel(usr));
  }



  /**
   * Busca todas as informações de um registro.
   * @param  {string} orderUuid
   */
  async get(orderUuid, user_uuid) {
    this.loading = true;
    const response = await API.get(orderUuid, user_uuid);
    this.loading = false;
    if (!response.error) {
      this.order = new OrderModel(response);
      return this.order;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async getByCustomer(uuid, size = 15, page = 0, sort = 'idOrder') {
    const response = await API.getByCustomer(uuid, {
      size,
      page,
      sort,
    });

    if (response.error) return [];
    this.ordersCustomer = response.content.map((usr) => new OrderModel(usr));
    this.totalPagesCustomer = response.totalPages;
    this.pageCustomer = response.number;
  }

  async getByCustomerExport(uuid, size = 1000) {
    const response = await API.getByCustomer(uuid, {
      size,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content.map((usr) => new OrderModel(usr));
  }

  async setFilters(page, filters) {
    this.filters = filters.search === {} ? 'status_statusName!CART' : filters;
    await this.getList();
  }

}
export default OrderStore;
