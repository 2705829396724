import { extendObservable } from 'mobx';
import _ from 'lodash';
import AdministratorAPI from '../services/AdministratorAPI';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import AdministratorModel from '~/models/AdministratorModel';
import AccountModel from '~/models/AccountModel';
import StorageUtil, { KEYS } from '~/helpers/utils/StorageUtil';
import UserAPI from '../services/UserAPI';
import { cryptoPass } from '~/helpers/utils/Functions';
import AuthoritiesModel from '~/models/AuthoritiesModel';

const initValues = {
  loading: false,
  _user: undefined,
  userList: [],
  totalPages: 0,
  size: 20,
  page: 0,
  sort: 'created,desc',
  administrator: undefined,
  listChecked: [],
  authorizations: [],
  defaultInputPerfil: [],
  loadingAction: false,
  excludeAuthorities: [],
  addAuthorities: [],
  listInitialGroups: [],
  copyAuthorities: [],
  excludedGroups: [],
  enabledUserGroup: false,
};

class UsersStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
  }

  reset() {
    this.totalPages = 0;
    this.page = 0;
    this.size = 10;
    this.sort = 'created,desc';
    this.checkboxAuthorizations = [];
    this.perfilInput = [];
    this.excludeAuthorities = [];
    this.addAuthorities = [];
    this.defaultInputPerfil = [];
    this.administrator = undefined;
    this.listChecked = [];
    this.listInitialGroups = [];
    this.loading = false;
    this.enabledUserGroup = false;
  }

  /** get Current user. */
  get user() {
    return this._user;
  }
  /**Set Current user */
  set user(value) {
    this._user = value;
  }

  /**Retorna merchanto do usuário */
  get userMerchant() {
    return this.user && this.user.merchant;
  }

  /**Verifica se a credencial do usuário é de superadmin */
  get isStaff() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === 'SUPERADMIN' || currentAuth === 'STOOM';
  }

  /**
   * Busca todas as informações de um usuario administrador.
   * @param  {string} userName
   */
  async get(userName) {
    this.loading = true;
    const response = await AdministratorAPI.getUserAdmin(userName);
    this.loading = false;
    if (!response.error) {
      this.user = new AccountModel(response);
      await this.rootStore.administratorStore.getAdminLogin(this.user);
      return this.user;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 20, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = 'name';
    sort ? (this.sort = sort) : (this.sort = 'name');

    await this.getUser();
    return true;
  }

  /**Busca todos os usuarios addmins */
  async getUser(size = 15, page = this.page, sort = this.sort) {
    this.loading = true;
    const response = await UserAPI.getUser({
      size,
      page,
      sort,
    });
    this.loading = false;
    if (response.error) return [];
    this.userList = response.content.map((usr) => new AdministratorModel(usr));

    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  /** Busca usuarios por uuid */
  async getUuidUser(uuid) {
    this.loading = true;
    const response = await UserAPI.getUuidUser(uuid);
    if (!response.error) {
      // Pega os itens Default do input
      this.defaultInputPerfil = response.user.authorityGroups.map((item) => ({
        value: item.uuid,
        label: item.name,
        authorities: item.authorities,
      }));

      this.administrator = new AdministratorModel(response);

      let autorithiesGroup = [];

      let $ = response?.user?.authorityGroups.forEach((group) => {
        group.authorities = group.authorities.map((authority) => ({
          ...authority,
          groupDefault: true,
        }));

        autorithiesGroup = _.concat(autorithiesGroup, group.authorities);
      });

      const userAuthorities = _.concat(
        autorithiesGroup,
        response.user?.authorities || []
      );

      this.copyAuthorities = _.cloneDeep(this.administrator.user.authorities);
      this.listChecked = userAuthorities.map((i) => {
        return {
          uuid: i.uuid,
          groupDefault: i.groupDefault ?? false,
        };
      });

      this.listInitialGroups = response.user.authorityGroups;
      this.administrator.user.authorities = userAuthorities;

      this.loading = false;
      return response;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Atualiza os campos do usuario */
  async updateAdministrator(uuid) {
    this.loading = true;

    this.administrator.user.password = 'temp';
    const deepCopiedObj = _.cloneDeep(this.administrator);
    deepCopiedObj.user.authorities = [];
    deepCopiedObj.user.authorityGroups = [];

    const user_uuid = this.administrator.user.uuid;

    await this.saveGroupAuthorizations();
    await this.saveAuthorizations(user_uuid);

    const response = await UserAPI.updateAdministrator(deepCopiedObj, uuid);

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Alterações gravadas com sucesso.'
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  async newAdministrator() {
    const account = this.administrator
      ? this.administrator
      : new AdministratorModel();
    this.administrator = new AdministratorModel(account);
  }

  /**Atualiza os campos do usuario */
  async createAdministrator() {
    this.loading = true;

    this.administrator.merchant = this.userMerchant;
    const deepCopiedObj = _.cloneDeep(this.administrator);
    deepCopiedObj.user.authorities = [];
    deepCopiedObj.user.authorityGroups = [];

    const response = await UserAPI.createAdministrator(deepCopiedObj);

    if (!response.error) {
      const userUuid = response?.data?.user?.uuid;
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Usúario criado com sucesso.'
      );

      if (this.administrator.user.authorityGroups.length > 0) {
        this.administrator.user.authorityGroups.map(async (item) => {
          await UserAPI.addGroupAuthoritie(item.value, userUuid);
        });
      }

      await this.saveAuthorizations(userUuid);
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  async saveAuthorizations(uuid) {
    const userUuid = uuid;

    //Função para adicionar itens que não estão nas authoritiesGroup
    const listGroupAuthorities = this.administrator.user.authorityGroups.map(
      (item) => {
        return item.authorities;
      }
    );

    // Metodo de Adição de autorização
    const itemUniqArray = this.addAuthorities.filter(
      (item) => !listGroupAuthorities.some((x) => x.uuid === item.uuid)
    );

    if (itemUniqArray.length > 0) {
      itemUniqArray.map((item) => {
        UserAPI.addAuthoritie(item.uuid, userUuid);
      });
    }

    //Veficicar se o item existe na listagem Inicial do delete

    // Metodo de Remoção
    if (this.excludeAuthorities.length > 0) {
      this.excludeAuthorities.map(async (item) => {
        await UserAPI.removeAuthoritie(item.uuid, userUuid);
      });
    }
  }

  async saveGroupAuthorizations(uuid) {
    const userUuid = this.administrator.user.uuid;

    //Pega todo elemento que não era existente no array
    const uniqueValues = this.administrator.user.authorityGroups.filter(
      (item1) =>
        !this.listInitialGroups.some(
          (item2) => item2.uuid === (item1.value || item1.uuid)
        )
    );

    //Verifica se o elemento não era identico ao que foi retornado na tela
    const hasSameValues =
      this.administrator.user.authorityGroups.length ===
        this.listInitialGroups.length &&
      this.administrator.user.authorityGroups.every((item1) =>
        this.listInitialGroups.find(
          (item2) => item2.uuid === (item1.value || item1.uuid)
        )
      );

    if (hasSameValues === false) {
      if (uniqueValues.length > 0) {
        uniqueValues.map((item) => {
          UserAPI.addGroupAuthoritie(item.value, userUuid);
        });
      }
    }

    // Excluir;
    if (this.excludedGroups.length > 0) {
      this.excludedGroups.map((item) => {
        UserAPI.deleteGroupAuthoritie(item.value, userUuid);
      });
    }
  }

  updateMultipleCheckbox(prop, uuid, value) {
    const { authorizations, administrator } = this;
    authorizations[prop] = uuid;
    this.authorizations = authorizations;
    const filterList = this.listChecked.filter((item) => item.uuid === value);

    if (filterList.length > 0) {
      // this.authorizations = new AuthoritiesModel();
    } else {
      //Adiciona os itens na lista
      const duplicateItem = [
        ...this.listChecked,
        { uuid: uuid, groupDefault: false },
      ];

      //Remove os itens duplicados
      const uniqueUuids = {};
      const filteredArray = duplicateItem.filter((item) => {
        if (item.uuid && !uniqueUuids[item.uuid]) {
          uniqueUuids[item.uuid] = true;
          return true;
        }
        return false;
      });

      this.listChecked = filteredArray;

      const removeItem = this.excludeAuthorities.filter(
        (i) => i.uuid !== authorizations.uuid
      );

      this.excludeAuthorities = removeItem;

      //Adiciona os itens da lista de Adição
      this.addAuthorities.push(authorizations);
      administrator.user.authorities.push(authorizations);
      this.authorizations = new AuthoritiesModel();
    }
  }

  updateCheckBoxAdministradorProp(prop, value) {
    const { authorizations, administrator } = this;
    authorizations[prop] = value;
    this.authorizations = authorizations;

    let verifyList = administrator.user.authorities.filter(
      (i) => i.uuid === authorizations.uuid
    );

    if (this.listChecked.some((item) => item.uuid === value)) {
      this.listChecked = this.listChecked.filter((a) => a.uuid !== value);
    } else {
      this.listChecked = [...this.listChecked, { uuid: value }];
    }

    if (verifyList.length > 0) {
      const removeItem = administrator.user.authorities.filter(
        (i) => i.uuid !== authorizations.uuid
      );
      administrator.user.authorities = removeItem;

      // Adiciona a lista de exclusão
      this.excludeAuthorities.push(authorizations);

      //Remove os itens da lista de Adição
      const removeAddItem = this.addAuthorities.filter(
        (i) => i.uuid !== authorizations.uuid
      );
      this.addAuthorities = removeAddItem;

      this.authorizations = new AuthoritiesModel();
    } else {
      //Remove o item da lista de exclusão
      const removeItem = this.excludeAuthorities.filter(
        (i) => i.uuid !== authorizations.uuid
      );

      this.excludeAuthorities = removeItem;

      //Adiciona os itens da lista de Adição
      this.addAuthorities.push(authorizations);
      administrator.user.authorities.push(authorizations);
      this.authorizations = new AuthoritiesModel();
    }
  }

  /**Atualiza uma propriedade do novo usuario*/
  async updatePropAdministrator(prop, value) {
    const account = this.administrator
      ? this.administrator
      : new AdministratorModel();
    switch (prop) {
      case 'username':
        account.user[prop] = value;
        account.username = value;
        break;
      case 'email':
        account.user[prop] = value;
        account.email = value;
        account.confirmEmail = value;
        break;
      case 'activeUser':
        account.user['active'] = value;
        break;
      case 'state':
        account.user[prop] = value;
        break;
      case 'pass':
        account.user.password = value;
        account.pass = value;
        break;
      case 'merchant':
        account.merchant = value;
        break;
      default:
        account[prop] = value;
    }

    this.enabledUserGroup =
      (account,
      account.user.username !== undefined &&
        account.user.email !== undefined &&
        account.user.password !== undefined &&
        account.name !== undefined);

    this.administrator = account;
  }

  async selectedAuthorities(itemUnique, item) {
    this.loadingAction = true;

    const data = item.map((item) => {
      return item.authorities;
    });

    const arrayConcatenado = [].concat(...data, ...this.copyAuthorities);

    if (itemUnique.action === 'remove-value') {
      this.excludedGroups.push(itemUnique.removedValue);
    } else {
      const removeGroupList = this.excludedGroups.filter(
        (obj) => obj.value !== itemUnique?.option?.value
      );
      this.excludedGroups = removeGroupList;
    }

    this.administrator.userGroup = item.length >= 1 ? 'OK' : '';
    this.administrator.user.authorities = arrayConcatenado;
    this.administrator.user.authorityGroups = item;

    this.listChecked = arrayConcatenado.map((i) => {
      return {
        uuid: i.uuid,
        groupDefault: true,
      };
    });
    this.loadingAction = false;
  }
}
export default UsersStore;
