import BaseAPI, { URLS } from './BaseAPI';

class PropertiesAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(`${URLS.PROPERTIES}/search`, params);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar a lista de especificações' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar a lista de especificações' };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(`${URLS.PROPERTIES}/classes`, data);

      return response.status === 201
        ? response.data
        : { error: 'Falha ao salvar especificação' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar especificação',
      };
    }
  }

  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.PROPERTIES}/classes/${uuid}`);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar especificação' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar especificação' };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(
        `${URLS.PROPERTIES}/property/classes/${uuid}`
      );
      return response.status === 204
        ? response.data
        : { error: 'Falha ao excluir especificação' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao excluir especificação' };
    }
  }

  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(
        `${URLS.PROPERTIES}/classes/${uuid}`,
        data
      );
      return response.status === 200
        ? response.data
        : { error: 'Falha ao atualizar especificação' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar especificação',
      };
    }
  }

  static async saveProperties(uuid, data) {
    try {
      const response = await BaseAPI.post(
        `${URLS.PROPERTIES}/classes/${uuid}/values`,
        data
      );
      return response.status === 200
        ? response.data
        : { error: 'Falha ao atualizar valor de especificação' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar valor de especificação',
      };
    }
  }

  static async deleteProperties(uuid) {
    try {
      const response = await BaseAPI.delete(
        `${URLS.PROPERTIES}/property/${uuid}`
      );
      return response.status === 200
        ? response.data
        : { error: 'Falha ao atualizar valor de especificação' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar valor de especificação',
      };
    }
  }
}

export default PropertiesAPI;
