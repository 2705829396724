import BaseAPI, { URLS } from './BaseAPI';

class InstitutionalAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(
        `${URLS.INSTITUTIONAL}/search`,
        params
      );
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar' };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.INSTITUTIONAL, data);

      return response.status === 200
        ? response.data
        : { error: 'Falha ao salvar' };
    } catch (e) {
      return {
        error: e?.response?.data
          ? e?.response?.data
          : 'Falha ao salvar',
      };
    }
  }

  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.INSTITUTIONAL}/${uuid}`, data);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao atualizar' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar Institucional',
      };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.INSTITUTIONAL}/${uuid}`);
      return response.status === 204
        ? response.data
        : { error: 'Falha ao excluir' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao excluir' };
    }
  }

  /**
   * Request que busca categoria por uuid
   * @param  {string} uuid - uuid do usuário
   */
  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.INSTITUTIONAL}/${uuid}`);
      if (response.status === 200) return response.data;
      return { error: 'Erro ao buscar categoria.' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar Categoria.');
    }
  }
}

export default InstitutionalAPI;
