import BaseAPI, { URLS } from './BaseAPI';

class OurStoresAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(URLS.STORES, params);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar a lista de lojas' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar a lista de lojas' };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.STORES, data);
      return response.status === 201 || response.status === 200
        ? response.data
        : { error: 'Falha ao salvar loja' };
    } catch (e) {
      if (e.response.status == 400) return { error: e.response.data.message };
      return { error: 'Falha ao salvar loja' };
    }
  }

  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.STORES}`, data);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao atualizar loja' };
    } catch (e) {
      if (e.response.status == 400) return { error: e.response.data.message };
      return { error: 'Falha ao salvar loja' };
    }
  }

  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.STORES}/${uuid}`);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar loja' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar loja' };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.STORES}/${uuid}`);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao excluir loja' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao excluir loja' };
    }
  }
}

export default OurStoresAPI;
