import { extendObservable } from 'mobx';

import VoltagemAPI from '../services/VoltagemAPI';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';

import VoltagemModel from '../models/VoltagemModel';

class VoltagemStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      voltagem: [],
      voltagens: [],
      vontagensListSelect: [],
      staffs: [],
      loading: false,
      superAdmin: false,
      totalPages: 0,
      size: 10,
      page: 0,
      sort: 'code',
    });
  }

  reset() {
    this.voltagem = [];
  }

  /**Atualiza um registro*/
  async onChange(prop, value) {
    const _voltagem = this.voltagem ? this.voltagem : new VoltagemModel();
    switch (prop) {
      default:
        _voltagem[prop] = value;
    }
    this.voltagem = new VoltagemModel(_voltagem);
  }

  /**Cria um novo registro */
  async onCreate() {
    this.loading = true;
    const data = JSON.stringify(this.voltagem);
    const response = await VoltagemAPI.save(data);
    this.loading = false;
    if (!response.error) {
      this.voltagem = new VoltagemModel();
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Voltagem criada com sucesso.');
    } else {
      if (response.error.includes('C�digo j� existente!')) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, 'Código já existente');
      } else {
        this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      }
    }
    return response;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 10, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = 'code';

    sort ? (this.sort = sort) : (this.sort = 'code');
    await this.getList();
    return true;
  }

  /**Busca todos os registros */
  async getList(size = 10, page = this.page, sort = this.sort) {
    this.loading = true;
    const response = await VoltagemAPI.getList({
      size,
      page,
      sort,
    });
    this.loading = false;
    if (response.error) return [];
    this.voltagens = response.content.map((usr) => new VoltagemModel(usr));
    this.totalPages = response.totalPages;
    this.page = response.number;
  }


  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters,
  ) {

    const response = await VoltagemAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
      return response.content.map((usr) => new VoltagemModel(usr));
  }


  /**
   * Busca todas as informações de um registro
   * @param  {string} userName
   */
  async get(userName) {
    this.loading = true;
    const response = await VoltagemAPI.get(userName);
    this.loading = false;
    if (!response.error) {
      this.voltagem = new VoltagemModel(response);
      return this.voltagem;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /** Atualiza um registro */
  async onUpdate() {
    this.loading = true;

    const data = JSON.stringify(this.voltagem);
    const response = await VoltagemAPI.update(this.voltagem.uuid, data);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Informações atualizadas com sucesso!'
      );
      this.voltagem = new VoltagemModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Deleta um registro */
  async delete(uuid) {
    this.loading = true;
    const response = await VoltagemAPI.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Voltagem excluída com sucesso!');
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, this.sort);
  }

  async getColorListSelect() {
    const response = await VoltagemAPI.getList({
      size: 100,
      page: 0,
      sort: 'code',
    });

    if (response.error) {
      this.vontagensListSelect = [];
      return;
    }

    this.vontagensListSelect = response.content.map((color) => ({
      value: color.uuid,
      label: color.code,
    }));
  }
}
export default VoltagemStore;
