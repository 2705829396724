const ProductsPermissionEnum = Object.freeze({
  POST: {
    pattern: '/manager/products',
    action: 'POST',
  },
  PUT: {
    pattern: '/manager/products/**',
    action: 'PUT',
  },
  DELETE: {
    pattern: '/manager/products/**',
    action: 'DELETE',
  },
  GET_PATCH: {
    pattern: '/manager/files/products/path/**',
    action: 'POST',
  },
  GET_SEARCH: {
    pattern: '/manager/products/search',
    action: 'GET',
  },
  GET: {
    pattern: '/manager/products/search/**',
    action: 'GET',
  },
  GET_CATEGORIES: {
    pattern: '/manager/products/**/categories',
    action: 'GET',
  },
  GET_CATEGORIES_PRODUCTS: {
    pattern: '/manager/categories/**/products',
    action: 'PUT',
  },
  GET_CATEGORIES_PRODUCTS_DELETE: {
    pattern: '/manager/categories/**/products',
    action: 'DELETE',
  },
  GET_UUID: {
    pattern: '/manager/products/**',
    action: 'GET',
  },
  FILES: {
    pattern: '/manager/files/products/**',
    action: 'POST',
  },
  FILE: {
    pattern: '/manager/files/products/file/**',
    action: 'POST',
  },
});

export default ProductsPermissionEnum;
