import BaseAPI, { URLS } from './BaseAPI';

class ConfigAPI {
  static async get() {
    try {
      const response = await BaseAPI.get(`${URLS.TRESCORACOESCONFIG}/unitDisplayController`);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar' };
    }
  }


  static async update(data) {
    try {
      const response = await BaseAPI.put(`${URLS.TRESCORACOESCONFIG}/${data.uuid}`, data);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao atualizar' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar Configuração da Loja',
      };
    }
  }
}

export default ConfigAPI;
