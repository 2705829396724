const reportAbandonedCartsEnum = Object.freeze({
  PUT: {
    pattern: '/manager/reports/abandoned-carts-general',
    action: 'PUT',
  },
  PUT_LIST: {
    pattern: '/manager/reports/abandoned-carts',
    action: 'PUT',
  },
});

export default reportAbandonedCartsEnum;
