import BaseAPI, { URLS } from './BaseAPI';

class CategoryAPI {
  /**Retorno generico de excessões */
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * Deleta Categoria.
   * @param  {string} uuid da categoria
   */
  static async delete(uuid) {
    try {
      const url = `${URLS.CATEGORIES}/${uuid}`;
      const response = await BaseAPI.delete(url);
      if (response.status === 200) return response.data;
      return { error: 'Não foi possível excluir categoria' };
    } catch (e) {
      return this._exception(
        e,
        'Não foi possível excluir categoria verifique vinculos'
      );
    }
  }

  /**
   * Request que busca categoria por uuid
   * @param  {string} uuid - uuid do usuário
   */
  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.CATEGORIES}/${uuid}`);
      if (response.status === 200) return response.data;
      return { error: 'Erro ao buscar categoria.' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar Categoria.');
    }
  }

  /**
   * Request que atualiza dados da categoria
   * @param  {string} uuid - uuid do usuário
   * @param  {object} data - dados da categoria
   */
  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.CATEGORIES}/${uuid}`, data);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao atualizar categoria.' };
    } catch (e) {
      if (e.response?.status === 500) {
        return { status: e.response.status, error: e.response.data.message };
      }
      return this._exception(e, 'Campo obrigatório!');
    }
  }

  /**
   * Request que cria novo produto.
   * @param  {Object} values - Objeto da categoria que será criado
   */
  static async save(values) {
    try {
      const response = await BaseAPI.post(URLS.CATEGORIES, values);
      if (response.status === 201) return response.data;
      return { error: 'Falha ao cadastrar categoria' };
    } catch (e) {
      if (e.response?.status === 500) {
        return { status: e.response.status, error: e.response.data.message };
      }
      return this._exception(e, 'Campo obrigatório!');
    }
  }
  /**
   * Request que vincula produtos em uma categoria.
   * @param  {Object} uuid - Uuid da categoria
   * @param  {Array} produts - Objeto produto que será invulado.
   */
  static async addProductsCategory(uuid, produts) {
    try {
      const url = `${URLS.CATEGORIES}/${uuid}/products`;
      const arrayProducts = Array.isArray(produts) ? produts : [produts];
      const response = await BaseAPI.put(url, arrayProducts);
      if (response.status === 200) return response;
      return { error: `Falha ao vincular categoria e produto` };
    } catch (e) {
      return this._exception(e, 'Falha ao vincular categoria e produto');
    }
  }

  /**
   * Request que vincula produtos em uma categoria.
   * @param  {Object} uuid - Uuid da categoria
   * @param  {Array} produts - Objeto produto que será invulado.
   */
  static async removeProductsCategory(uuid, produts) {
    try {
      const url = `${URLS.CATEGORIES}/${uuid}/products`;
      const arrayProducts = Array.isArray(produts) ? produts : [produts];
      const response = await BaseAPI.delete(url, arrayProducts);
      if (response.status === 200) return response;
      return { error: `Falha ao desvincular categoria e produto` };
    } catch (e) {
      return this._exception(e, 'Falha ao desvincular categoria e produto');
    }
  }

  /**
   * @param  {object} params - parametros de paginação
   */
  static async getList(params) {
    try {
      const response = await BaseAPI.get(URLS.CATEGORIES, params);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao buscar lista de categorias' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar lista de categorias');
    }
  }

  /**
   */
  static async getTree() {
    try {
      const response = await BaseAPI.get(`${URLS.CATEGORIES}/tree?size=9999`);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao buscar lista de categorias' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar lista de categorias');
    }
  }

  /**
   * Deleta imagem do banner
   * @param  {string} bannerUuid - uuid do banner
   * @param  {string} bannerFileUuid - uuid do objeto que a imagem foi salva
   */
  static async deleteImageBanner(bannerUuid, bannerFileUuid) {
    try {
      const url = `${URLS.BANNER}/${bannerUuid}/image/${bannerFileUuid}`;
      const response = await BaseAPI.delete(url);
      if (response.status === 204) return response.data;
      return {
        error: 'Não foi possível excluir a imagem do banner.',
      };
    } catch (e) {
      return this._exception(e, 'Não foi possível excluir a imagem do banner.');
    }
  }
}

export default CategoryAPI;
