const OrdersPermissionEnum = Object.freeze({
  POST: {
    pattern: '/manager/orders/customers',
    action: 'POST',
  },
  PUT: {
    pattern: '/manager/orders/customers/**',
    action: 'PUT',
  },
  DELETE: {
    pattern: '/manager/orders/customers/**',
    action: 'DELETE',
  },
  GET: {
    pattern: '/manager/orders/search',
    action: 'GET',
  },
  GET_UUID: {
    pattern: '/manager/orders/**/customers/**',
    action: 'GET',
  },
});

export default OrdersPermissionEnum;
