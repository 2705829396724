const CampainsPermissionEnum = Object.freeze({
  POST: {
    pattern: '/manager/campaigns',
    action: 'POST',
  },
  PUT: {
    pattern: '/manager/campaigns/**',
    action: 'PUT',
  },
  DELETE: {
    pattern: '/manager/campaigns/**',
    action: 'DELETE',
  },
  GET: {
    pattern: '/manager/campaigns/search',
    action: 'GET',
  },
  GET_UUID: {
    pattern: '/manager/campaigns/**',
    action: 'GET',
  },
});

export default CampainsPermissionEnum;
