import { extendObservable } from 'mobx';
import StampModel from '../models/StampModel';
import StampAPI from '../services/StampAPI';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import FileModel from '~/models/FileModel';
import UploadAPI from '../services/UploadAPI';

class StampStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      unit: undefined,
      loading: false,
      imageToAdd: undefined,
      stamp: new StampModel(),
      stamps: [],
      totalPages: 0,
      page: 0,
      size: 10,
    });
  }

  reset() {
    this.unit = [];
    this.loading = false;
    this.stamp = new StampModel();
  }

  async getList(size = this.size, page = this.page, sort = this.sort) {
    this.loading = true;
    const response = await StampAPI.getList(
      {
        size,
        page,
        sort,
      }
    );
    if (response.error) return [];
    this.stamps = response.result.map((usr) => new StampModel(usr));
    this.totalPages = response.result.totalPages;
    this.page = response.result.number;
    this.loading = false;
  }

  async get(uuid) {
    this.loading = true;
    const response = await StampAPI.get(uuid);
    if (!response.error) {
      this.stamp = new StampModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  onChange(prop, value) {
    switch (prop) {
      default:
        this.stamp[prop] = value;
    }
  }

  handleUploadImage(file) {
    this.loading = true
    const newFileItem = new FileModel({
      file,
      ...file,
      metaTags: ['selo'],
      size: 1,
      path: file?.preview,
      uuid: Math.random() * 5
    });
    this.imageToAdd = newFileItem

    this.loading = false
    this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Imagem cadastrada com sucesso!');
  }

  async sendFiles() {
    this.imageToAdd &&
      await UploadAPI.uploadImageHeaderStoreSetup(this.imageToAdd);
  }

  async onCreate() {
    this.loading = true;

    const response = await StampAPI.save(this.stamp);
    this.stamp = new StampModel()
    if (!response.error) {
      this.sendFiles()
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Informações atualizadas com sucesso!'
      );
      this.stamps.push(new StampModel(response))

    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  async onUpdate() {
    this.loading = true;
    const data = JSON.stringify(this.stamp);

    const response = await StampAPI.update(
      this.stamp.uuid,
      data
    );
    this.loading = false;
    if (response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        'Erro ao editar Selo'
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Dados atualizados');
    }
    return response;
  }

  async delete(uuid) {
    this.loading = true;
    const response = await StampAPI.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Excluído com sucesso!');
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, this.sort);
  }

  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
  ) {
    const response = await StampAPI.getList({
      size,
      page,
      sort,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }

    console.log('response', response);
    return response.result.map((value) => new StampModel(value));
  }
}
export default StampStore;
