import UserModel from './UserModel';
import MerchantModel from './MerchantModel';
import BaseModel from './BaseModel';
import { convertPrice, dateToText } from '../helpers/utils/Functions';

class AbandonedCartModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.created = dateToText(data?.created);
    this.firstName = data?.customer?.corporateName;
    this.canal = data?.customer?.subChannel?.channel.name;
    this.subcanal = data?.customer?.subChannel?.name;
    this.total = convertPrice(data?.total);
  }
}

export default AbandonedCartModel;
