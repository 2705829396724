import BaseModel from './BaseModel';
import OrderStatusModel from './OrderStatusModel';
import CustomerModel from './CustomerModel';
import OrderItemsModel from './OrderItemsModel';
import PaymentDetailsModel from './PaymentDetailsModel';
import ShippingDetailsModel from './ShippingDetailsModel';
import { formatNumbers } from '~/helpers/utils/Functions';

export default class OrderModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.idOrder = data.idOrder;
    this.status = data.status && new OrderStatusModel(data.status);
    this.customer = data.customer && new CustomerModel(data.customer);
    this.orderNumber = data.orderNumber;
    this.orderInternalId = data.orderInternalId;
    this.orderItems =
      data?.orderItems?.length > 0
        ? data.orderItems.filter((props) => {
          if (props.uuid != undefined) return new OrderItemsModel(props);
        })
        : [];
    this.shippingDate = data.shippingDate;
    this.paymentDetails = data.paymentDetails
      ? new PaymentDetailsModel(data.paymentDetails)
      : '';
    this.shippingDetails =
      data.shippingDetails && new ShippingDetailsModel(data.shippingDetails);
    this.productsValue = data.productsValue;
    this.quantityOfProducts =
      data?.orderItems?.length > 0 ? data.orderItems.length : 0;
    this.productsDiscounts = data.productsDiscounts;
    this.productsTotal = data.productsTotal;
    this.paymentDiscounts = data.paymentDiscounts;
    this.voucherDiscounts = data.voucherDiscounts;
    this.campaignDiscounts = data.campaignDiscounts;
    this.totalDiscounts = `R$ ${formatNumbers(data.totalDiscounts)}`;
    this.totalValue = `R$ ${formatNumbers(data.totalValue)}`;
    this.total = `R$ ${formatNumbers(data.total)}`;
    this.totalBilled = `R$ ${formatNumbers(data.totalBilled)}`;
    this.invoiceDiscounts = data.invoiceDiscounts;
    this.nfNumber = data.nfNumber;
    this.sentErp = data.sentErp;
    this.billed = data.billed;
    this.canceledErp = data.canceledErp;
    this.statusChanges = data.statusChanges;
    this.source = data.source;
    this.sourceName = data.sourceName;
    this.status_statusName = data.status_statusName;
  }
}
