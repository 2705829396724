const ComponentsPermissionEnum = Object.freeze({
  POST: {
    pattern: "/manager/components",
    action: 'POST',
  },
  PUT: {
    pattern: "/manager/components/**",
    action: 'PUT',
  },
  DELETE: {
    pattern: "/manager/components/**",
    action: 'DELETE',
  },
  GET: {
    pattern: "/manager/components/**",
    action: 'GET',
  },
  GET_FILTER: {
    pattern: "/manager/components/filter",
    action: 'GET',
  },
});

export default ComponentsPermissionEnum;
