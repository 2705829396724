import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import LoadingComponent from '~/components/LoadingComponent/LoadingComponent';

//Imports apenas importados caso forem requisitados pela aplicação.
const HomePage = lazy(() => import('~/pages/HomePage/HomePage'));
const NotFound = lazy(() => import('~/pages/NotFound/NotFound'));
const LoginCenterPage = lazy(() =>
  import('~/pages/LoginCenterPage/LoginCenterPage')
);

const HomeContainer = lazy(() => import('~/containers/HomeContainer'));

//Componenentes de acordo ao item de menu
const ManagerCustomerContainer = lazy(() =>
  import('~/containers/ManagerCustomerContainer')
);
const InstallmentsCondition = lazy(() =>
  import('~/containers/InstallmentsCondition')
);

const ManagerOrderContainer = lazy(() =>
  import('~/containers/ManagerOrderContainer')
);
const AdministratorContainer = lazy(() =>
  import('~/containers/AccountContainer')
);
const ManagerAccountContainer = lazy(() =>
  import('~/containers/ManagerAccountContainer')
);
const ManagerCategoryContainer = lazy(() =>
  import('~/containers/ManagerCategoryContainer')
);
const CategoryContainer = lazy(() => import('~/containers/CategoryContainer'));
const ManagerProductsContainer = lazy(() =>
  import('~/containers/ManagerProductsContainer')
);
const ProductContainer = lazy(() => import('~/containers/ProductContainer'));
const ProductVariationContainer = lazy(() =>
  import('~/containers/ProductVariationContainer')
);
const BannerContainer = lazy(() => import('~/containers/BannerContainer'));
const ManagerBannersContainer = lazy(() =>
  import('~/containers/ManagerBannersContainer')
);
const ManagerDynamicHomeContainer = lazy(() =>
  import('~/containers/ManagerDynamicHomeContainer')
);

const ManagerHomeChannelContainer = lazy(() =>
  import('~/containers/ManagerDynamicHomeContainer/ManagerHomeChannelContainer')
);
const DynamicHomeContainer = lazy(() =>
  import('~/containers/DynamicHomeContainer')
);
const ColorContainer = lazy(() => import('~/containers/ColorContainer'));
const ManagerColorContainer = lazy(() =>
  import('~/containers/ManagerColorContainer')
);
const ChannelContainer = lazy(() => import('~/containers/ChannelContainer'));
const ManagerChannelContainer = lazy(() =>
  import('~/containers/ManagerChannelContainer')
);
const SubChannelContainer = lazy(() =>
  import('~/containers/SubChannelContainer')
);
const ManagerSubChannelContainer = lazy(() =>
  import('~/containers/ManagerSubChannelContainer')
);
const CustomerContainer = lazy(() => import('~/containers/CustomerContainer'));
const ViewCustomerContainer = lazy(() =>
  import('~/containers/CustomerContainer/view/ViewCustomerContainer')
);

const ViewOrderContainer = lazy(() =>
  import('~/containers/OrderContainer/ViewOrderContainer')
);

const ManagerSizeContainer = lazy(() =>
  import('~/containers/ManagerSizeContainer')
);
const SizeContainer = lazy(() => import('~/containers/SizeContainer'));
const UnitContainer = lazy(() => import('~/containers/UnitContainer'));
const ManagerUnitsContainer = lazy(() =>
  import('~/containers/ManagerUnitsContainer')
);
const ManegerTemplatesEmailContainer = lazy(() =>
  import('~/containers/ManegerTemplatesEmailContainer')
);
const ManagerUserGroupsContainer = lazy(() =>
  import('~/containers/ManagerUserGroupsContainer')
);
const ManagerUserContainer = lazy(() =>
  import('~/containers/ManagerUserContainer')
);

const TemplateEmailContainer = lazy(() =>
  import('~/containers/TemplateEmailContainer')
);
const UserGroupContainer = lazy(() =>
  import('~/containers/UserGroupContainer')
);
const UserContainer = lazy(() => import('~/containers/UserContainer'));
const ManagerSalesContainer = lazy(() =>
  import('~/containers/ManagerSalesContainer')
);
const ManagerReportProductContainer = lazy(() =>
  import('~/containers/ManagerReportProductContainer')
);
const ManagerReportAbandonedCartContainer = lazy(() =>
  import('~/containers/ManagerReportAbandonedCartContainer')
);
const ManagerReportProductMostViewdContainer = lazy(() =>
  import('~/containers/ManagerReportProductMostViewdContainer')
);
const ManagerReportProductEvaluationContainer = lazy(() =>
  import('~/containers/ManagerReportProductEvaluationContainer')
);
const ManagerReportCategorySales = lazy(() =>
  import('~/containers/ManagerReportCategorySales')
);
const ManagerReportCampaing = lazy(() =>
  import('~/containers/ManagerReportCampaing')
);
const ManagerReportCupom = lazy(() =>
  import('~/containers/ManagerReportCupom')
);
const ManagerReportLog = lazy(() => import('~/containers/ManagerReportLog'));
const ManagerPropertiesContainer = lazy(() =>
  import('~/containers/ManagerPropertiesContainer')
);
const PropertiesContainer = lazy(() =>
  import('~/containers/PropertiesContainer')
);
const ManagerVoltagemContainer = lazy(() =>
  import('~/containers/ManagerVoltagemContainer')
);
const VoltagemContainer = lazy(() => import('~/containers/VoltagemContainer'));
const ManagerMaterialContainer = lazy(() =>
  import('~/containers/ManagerMaterialContainer')
);
const MaterialContainer = lazy(() => import('~/containers/MaterialContainer'));

const ManagerProductReviewsContainer = lazy(() =>
  import('~/containers/ManagerProductReviewsContainer')
);

const ManagerCampaignContainer = lazy(() =>
  import('~/containers/ManagerCampaignContainer')
);

const CampaignContainer = lazy(() => import('~/containers/CampaignContainer'));

const ManagerCouponContainer = lazy(() =>
  import('~/containers/ManagerCouponContainer')
);

const CouponContainer = lazy(() => import('~/containers/CouponContainer'));

const ManagerStoreSetup = lazy(() => import('~/containers/ManagerStoreSetup'));

const ShippingContainer = lazy(() => import('~/containers/ShippingContainer'));
const ManagerShippingContainer = lazy(() =>
  import('~/containers/ManagerShippingContainer')
);
const ShippingUploadContainer = lazy(() =>
  import('~/containers/ShippingUploadContainer')
);

const ManagerDashboardContainer = lazy(() =>
  import('~/containers/ManagerDashboardContainer')
);

const ManagerPerfilContainer = lazy(() =>
  import('~/containers/ManagerPerfilContainer')
);

const ManagerInstitucionalContainer = lazy(() =>
  import('~/containers/ManagerInstitucionalContainer')
);

const InstitucionalContainer = lazy(() =>
  import('~/containers/InstitucionalContainer')
);

const ManagerStampsContainer = lazy(() =>
  import('~/containers/ManagerStampsContainer')
);

const StampContainer = lazy(() => import('~/containers/StampContainer'));

const PerfilContainer = lazy(() => import('~/containers/PerfilContainer'));
//Rotas globais de nossa aplicação manager.
export default inject(({ authStore }) => ({ authStore }))(
  withRouter(
    observer((props) => {
      const logout = () => {
        props.authStore.logout().then(() => props.history.push('/'));
      };

      return (
        <Suspense fallback={<LoadingComponent />}>
          <Switch>
            <Route exact path="/error-page" component={NotFound} />
            <Route exact path="/login" render={() => <Redirect to={'/'} />} />
            <Route exact path="/logout" render={() => logout()} />

            <PrivateRoute
              path="/"
              component={HomePage}
              isAuthenticated={props.authStore.isAuthenticated}
            />
            <Redirect to="/error-page" />
          </Switch>
        </Suspense>
      );
    })
  )
);

export const HomeRoutes = () => (
  <Switch>
    <Route exact path="/" component={HomeContainer} />
    <Route exact path="/dashboard" component={ManagerDashboardContainer} />

    {/* 1 - */}

    <Route exact path="/clientes" component={ManagerCustomerContainer} />
    <Route
      exact
      path="/editar-cliente/:uuidCustomer"
      component={CustomerContainer}
    />
    <Route
      exact
      path="/visualizar-cliente/:uuidCustomer"
      component={ViewCustomerContainer}
    />

    <Route exact path="/canal" component={ManagerChannelContainer} />
    <Route exact path="/cadastrar-canal" component={ChannelContainer} />
    <Route exact path="/editar-canal/:uuidCanal" component={ChannelContainer} />

    <Route exact path="/sub-canal" component={ManagerSubChannelContainer} />
    <Route exact path="/cadastrar-sub-canal" component={SubChannelContainer} />
    <Route
      exact
      path="/editar-sub-canal/:uuidSubCanal"
      component={SubChannelContainer}
    />

    <Route
      exact
      path="/condicoes-parcelamento"
      component={InstallmentsCondition}
    />

    <Route
      exact
      path="/institucional"
      component={ManagerInstitucionalContainer}
    />

    <Route
      exact
      path="/cadastrar-institucional"
      component={InstitucionalContainer}
    />

    <Route
      exact
      path="/editar-institucional/:uuidInstitucional"
      component={InstitucionalContainer}
    />

    {/* 2 - */}
    <Route exact path="/administradores" component={ManagerAccountContainer} />
    <Route
      exact
      path="/cadastrar-administrador"
      component={AdministratorContainer}
    />
    <Route
      exact
      path="/editar-administrador/:uuidAdministrator"
      component={AdministratorContainer}
    />

    <Route exact path="/usuarios" component={ManagerUserContainer} />
    <Route exact path="/cadastrar-usuario" component={UserContainer} />
    <Route exact path="/editar-usuario/:uuidUnit" component={UserContainer} />

    <Route
      exact
      path="/grupos-usuario"
      component={ManagerUserGroupsContainer}
    />
    <Route
      exact
      path="/cadastrar-grupo-usuario"
      component={UserGroupContainer}
    />
    <Route
      exact
      path="/editar-grupo-usuario/:uuidUnit"
      component={UserGroupContainer}
    />

    <Route path="/perfil" component={ManagerPerfilContainer} />
    <Route path="/novo-perfil" component={PerfilContainer} />
    <Route path="/editar-perfil/:perfilUuid" component={PerfilContainer} />

    {/* 3 - */}

    <Route exact path="/pedidos" component={ManagerOrderContainer} />
    <Route
      exact
      path="/visualizar-pedido/:uuidOrder/customers/:customerUuid"
      component={ViewOrderContainer}
    />
    {/* 4 - */}
    <Route exact path="/home" component={ManagerDynamicHomeContainer} />
    <Route
      exact
      path="/home/canal/:uuidChannel"
      component={ManagerHomeChannelContainer}
    />
    <Route
      exact
      path="/cadastrar-componente"
      component={DynamicHomeContainer}
    />
    <Route
      exact
      path="/editar-home/:branchUuid"
      component={DynamicHomeContainer}
    />

    {/* 5 - */}
    <Route exact path="/banners" component={ManagerBannersContainer} />
    <Route exact path="/cadastrar-banner" component={BannerContainer} />
    <Route
      exact
      path="/editar-banner/:bannerUuid"
      component={BannerContainer}
    />

    {/* 6 - */}
    <Route exact path="/categorias" component={ManagerCategoryContainer} />
    <Route exact path="/cadastrar-categoria" component={CategoryContainer} />
    <Route
      exact
      path="/editar-categoria/:uuidCategory"
      component={CategoryContainer}
    />

    {/* 7 - */}
    <Route exact path="/produtos/:type" component={ManagerProductsContainer} />

    <Route exact path="/cadastrar-produtos" component={ProductContainer} />
    <Route
      exact
      path="/editar-produto/:uuidProduct"
      component={ProductContainer}
    />

    <Route
      exact
      path="/cadastrar-variacao-produtos"
      component={ProductVariationContainer}
    />

    <Route
      exact
      path="/editar-variacao-produto/:uuidProduct"
      component={ProductVariationContainer}
    />

    <Route
      exact
      path="/gerenciar-avaliacoes"
      component={ManagerProductReviewsContainer}
    />

    {/* 8 - Atributos */}
    <Route exact path="/cores" component={ManagerColorContainer} />
    <Route exact path="/cadastrar-cor" component={ColorContainer} />
    <Route exact path="/editar-cor/:uuidColor" component={ColorContainer} />

    <Route exact path="/tamanhos" component={ManagerSizeContainer} />
    <Route exact path="/cadastrar-tamanho" component={SizeContainer} />
    <Route exact path="/editar-tamanho/:uuidSize" component={SizeContainer} />

    <Route exact path="/voltagem" component={ManagerVoltagemContainer} />
    <Route exact path="/cadastrar-voltagem" component={VoltagemContainer} />
    <Route
      exact
      path="/editar-voltagem/:uuidVoltagem"
      component={VoltagemContainer}
    />

    <Route exact path="/material" component={ManagerMaterialContainer} />
    <Route exact path="/cadastrar-material" component={MaterialContainer} />
    <Route
      exact
      path="/editar-material/:uuidMaterial"
      component={MaterialContainer}
    />

    {/* 9 - */}
    <Route exact path="/unidades" component={ManagerUnitsContainer} />
    <Route exact path="/cadastrar-unidade" component={UnitContainer} />
    <Route exact path="/editar-unidade/:uuidUnit" component={UnitContainer} />

    {/* Templates de e-mail */}
    <Route
      exact
      path="/lista-templates"
      component={ManegerTemplatesEmailContainer}
    />
    <Route
      exact
      path="/cadastrar-template"
      component={TemplateEmailContainer}
    />
    <Route
      exact
      path="/editar-template/:uuidUnit"
      component={TemplateEmailContainer}
    />

    {/* Configurações da loja */}
    <Route exact path="/configuracoes-loja" component={ManagerStoreSetup} />

    {/* 10 - */}
    <Route exact path="/propriedades" component={ManagerPropertiesContainer} />
    <Route
      exact
      path="/cadastrar-propriedade"
      component={PropertiesContainer}
    />
    <Route
      exact
      path="/editar-propriedade/:uuidProperties"
      component={PropertiesContainer}
    />

    {/* 11 */}
    <Route exact path="/campanhas" component={ManagerCampaignContainer} />
    <Route exact path="/cadastrar-campanha" component={CampaignContainer} />
    <Route
      exact
      path="/editar-campanha/:campaignUuid"
      component={CampaignContainer}
    />

    {/* 12 - */}
    <Route exact path="/cupons" component={ManagerCouponContainer} />
    <Route exact path="/cadastrar-cupom" component={CouponContainer} />
    <Route exact path="/editar-cupom/:couponUuid" component={CouponContainer} />
    {/* 13 - */}
    <Route exact path="/vendas/general" component={ManagerSalesContainer} />
    <Route
      exact
      path="/vendas/carrinho-abandonado"
      component={ManagerReportAbandonedCartContainer}
    />
    <Route
      exact
      path="/vendas/produto"
      component={ManagerReportProductContainer}
    />
    <Route
      exact
      path="/vendas/produtos-mais-vistos"
      component={ManagerReportProductMostViewdContainer}
    />
    <Route
      exact
      path="/vendas/avaliacao-produtos"
      component={ManagerReportProductEvaluationContainer}
    />
    <Route
      exact
      path="/vendas/categorias"
      component={ManagerReportCategorySales}
    />
    <Route exact path="/vendas/campanha" component={ManagerReportCampaing} />
    <Route exact path="/vendas/cupom" component={ManagerReportCupom} />
    <Route exact path="/vendas/logs" component={ManagerReportLog} />

    <Route exact path="/fretes" component={ManagerShippingContainer} />
    <Route exact path="/cadastrar-frete" component={ShippingContainer} />
    <Route exact path="/upload-frete" component={ShippingUploadContainer} />
    <Route
      exact
      path="/editar-frete/:shppingUuid"
      component={ShippingContainer}
    />

    {/* 14 - */}
    <Route exact path="/selos-footer" component={ManagerStampsContainer} />
    <Route exact path="/cadastrar-selo" component={StampContainer} />
    <Route exact path="/editar-selo/:stampUuid" component={StampContainer} />
  </Switch>
);

/*Private route é responsável por verificar se o usuário está logado ou não. */
const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <LoginCenterPage {...props} />
      )
    }
  />
);
