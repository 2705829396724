import BaseAPI, { URLS } from './BaseAPI';

class ColorAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(`${URLS.COLORS}/search`, params);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar a lista de cores' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar a lista de cores' };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.COLORS, data);
      return response.status === 201
        ? response.data
        : { error: 'Falha ao salvar cor' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar cor',
      };
    }
  }

  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.COLORS}/${uuid}`, data);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao atualizar cor' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar cor',
      };
    }
  }

  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.COLORS}/${uuid}`);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar cor' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar cor' };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.COLORS}/${uuid}`);
      return response.status === 204
        ? response.data
        : { error: 'Falha ao excluir cor' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao excluir cor' };
    }
  }
}

export default ColorAPI;
