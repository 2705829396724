import BaseAPI, { URLS } from './BaseAPI';
import StorageUtil, { KEYS } from '~/helpers/utils/StorageUtil';

/**AuthRequests é a camada onde incluímos a lógica que conversa direto com o backend e devolve o dado para  store.. */
class AuthAPI {
  /**Autentica o usuário e grava o token no localstorage */
  static async login(data, code) {
    try {
      const response = await BaseAPI.post(URLS.LOGIN, data, {
        headers: {
          code,
        },
      });
      StorageUtil.setItem(KEYS.USER_KEY, data.username);
      return response;
    } catch (e) {
      console.log(e.message);
      return { error: "Não autenticado" };
    }
  }

  static async authLogin() {
    try {
      const response = await BaseAPI.get(URLS.AUTH);
      return response;
    } catch (e) {
      return { error: 'Não autenticado' };
    }
  }

  /**Logout - desloga o usuário no backend e remove do localstorage
   * OBS: Hoje não possuímos nenhum endpoint para invalidar o token. Portanto estamos só removendo do localStorage.
   * Se amanhã ou depois vier a possuir algo do lado do back a estrutura já está pronta e deverá ser trabalhado aqui.
   */
  static async logout() {
    StorageUtil.cleanAll();
    return true;
  }

  /**Faz requisição de redefinição de senha do email informado */
  static async redefinePass(email) {
    try {
      const response = await BaseAPI.post(URLS.FORGET_PASSWORD, { email });
      return response;
    } catch (e) {
      return { error: 'A recuperação de senha falhou' };
    }
  }

  static async validateToken(token) {
    try {
      const response = await BaseAPI.get(`${URLS.FORGET_PASSWORD}/${token}`);
      return response;
    } catch (e) {
      return { error: 'A recuperação de senha falhou' };
    }
  }

  static async changePassword(email, token, newPassword) {
    try {
      const response = await BaseAPI.post(`${URLS.FORGET_PASSWORD}/update`, {
        email,
        token,
        newPassword,
      });
      return response;
    } catch (e) {
      return { error: 'A recuperação de senha falhou' };
    }
  }

  static async twoFactorAuthentication(data) {
    try {
      const response = await BaseAPI.put('/administrator/2fa', data);
      if (response.status === 200) return response.data;
    } catch (e) {
      console.log(e.message);
      return { error: 'Não autenticado' };
    }
  }
}

export default AuthAPI;
