import { extendObservable } from 'mobx';

import DashboardAPI from '../services/DashboardAPI';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';

class DashboardStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      quantityOrder: {
        canceledOrder: 0,
        placedOrder: 0,
        finishedOrder: 0,
      },
      customer: {
        totalCustomer: 0,
        activeCustomer: 0,
        inactiveCustomer: 0,
        data: [],
        colors: ['#28292A', '#E3E3E3', '#828282'],
      },
      listOrdes: {
        data: [],
      },
      amountOfSalesPerMonth: {
        data: [],
      },
      monthlyBilling: {
        data: [],
      },
      dailyBilling: {
        data: [],
      },
      conversionRatesAndAddtoCart: {
        cart: [],
        data: [],
        conversion: [],
      },
      defaultTo: '',
      defaultFrom: '',
      bestSellingCategory: [],
      loading: false,
    });
  }

  reset() {
    this.dashboards = [];
    this.loading = false;
  }

  async getFirstFilterDates() {
    const fDate = new Date();
    const tDate = new Date();

    fDate.setDate(fDate.getDate() - 30);
    const fromYear = fDate.getFullYear();
    const fromMonth =
      fDate.getMonth() + 1 >= 10
        ? fDate.getMonth() + 1
        : `0${fDate.getMonth() + 1}`;
    const fromDay =
      fDate.getDate() >= 10 ? fDate.getDate() : `0${fDate.getDate()}`;
    const fromDate = `${fromYear}-${fromMonth}-${fromDay}`;

    const toYear = tDate.getFullYear();
    const toMonth =
      tDate.getMonth() + 1 >= 10
        ? tDate.getMonth() + 1
        : `0${tDate.getMonth() + 1}`;
    const toDay =
      tDate.getDate() >= 10 ? tDate.getDate() : `0${tDate.getDate()}`;
    const toDate = `${toYear}-${toMonth}-${toDay}`;

    this.defaultTo = toDate;
    this.defaultFrom = fromDate;
  }


  /**Busca todos os registros */
  async getList() {
    this.loading = true;
    const response = await DashboardAPI.getList();
    const responseCategory = await DashboardAPI.getListCategory({
      '@class':
        'br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO',
      from: this.defaultFrom + "T00:00:00.556",
      to: this.defaultTo + "T23:59:59.556",
      limit: 1,
    });

    this.loading = false;

    if (responseCategory.error) return [];
    if (response.error) return [];
    this.bestSellingCategory = responseCategory.content;

    response.map((e) => {
      if (e.name === 'Quantidade Pedidos') {
        e.series.map((serie) => {
          if (serie?.name === 'Pedidos Cancelados') {
            this.quantityOrder.canceledOrder = Number(serie.data[0]);
          } else if (serie?.name === 'Pedidos Realizados') {
            this.quantityOrder.placedOrder = Number(serie.data[0]);
          } else if (serie?.name === 'Pedidos Finalizados') {
            this.quantityOrder.finishedOrder = Number(serie.data[0]);
          }
        });
      } else if (e.name === 'Quantidade de Clientes') {
        e.series.map((serie) => {
          if (serie?.name === 'Total Clientes') {
            this.customer.totalCustomer = Number(serie.data[0]);
            this.customer.data.push({
              name: serie?.name,
              value: Number(serie.data[0]),
            });
          } else if (serie?.name === 'Clientes Ativos') {
            this.customer.activeCustomer = Number(serie.data[0]);
            this.customer.data.push({
              name: serie?.name,
              value: Number(serie.data[0]),
            });
          } else if (serie?.name === 'Clientes Inativos') {
            this.customer.inactiveCustomer = Number(serie.data[0]);
            this.customer.data.push({
              name: serie?.name,
              value: Number(serie.data[0]),
            });
          }
        });
      } else if (e.name === 'Quantidade Pedidos Mês a Mês Ano Atual') {
        let canceledOrder = [];
        let totalOrders = [];
        let totalOrdersFinished = [];
        let totalDates = [];
        e.series.map((serie, index) => {
          if (serie.name === 'Pedidos Cancelados') {
            canceledOrder = serie.data;
          } else if (serie.name === 'Total Pedidos') {
            totalOrders = serie.data;
          } else if (serie.name === 'Total Pedidos Finalizados') {
            totalOrdersFinished = serie.data;
          }
        });

        if (e.xaxis.name === 'Tempo') {
          const meses = [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Out',
            'Nov',
            'Dez',
          ];
          const data = e.xaxis.data.map((dt) => {
            let dtf = new Date(dt);
            return meses[dtf.getMonth()];
          });
          totalDates = data;
        }

        let arrayOrder = [];
        var i = 0;
        for (; i < canceledOrder.length; i++) {
          arrayOrder.push({
            Cancelados: Number(canceledOrder.find((_, index) => index == i)),
            Feitos: Number(totalOrders.find((_, index) => index == i)),
            'Entregues/Finalizados': Number(
              totalOrdersFinished.find((_, index) => index == i)
            ),
            name: totalDates.find((_, index) => index == i),
          });
        }

        this.listOrdes.data = arrayOrder;
      } else if (e.name === 'Quantidade de Vendas (Itens)') {
        let currentYear = [];
        let lastYear = [];
        let totalDates = [];
        e.series.map((serie, index) => {
          if (serie.name === 'Itens Ano Atual') {
            currentYear = serie.data.map((e) => Number(e));
          } else if (serie.name === 'Itens Ano Passado') {
            lastYear = serie.data.map((e) => Number(e));
          }
        });

        if (e.xaxis.name === 'Mês') {
          const meses = [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Out',
            'Nov',
            'Dez',
          ];
          const data = e.xaxis.data.map((dt) => {
            let dtf = new Date(dt);
            return meses[dtf.getMonth()];
          });
          totalDates = data;
        }

        let arrayOrder = [];
        var i = 0;
        for (; i < currentYear.length; i++) {
          arrayOrder.push({
            Atual: currentYear.find((_, index) => index == i),
            Passado: lastYear.find((_, index) => index == i),
            name: totalDates.find((_, index) => index == i),
          });
        }

        this.amountOfSalesPerMonth.data = arrayOrder;
      } else if (e.name === 'Receita Mês a Mês Ano Atual x Ano Passado') {
        let currentYear = [];
        let lastYear = [];
        let totalDates = [];
        e.series.map((serie, index) => {
          if (serie.name === 'Receita Ano Atual') {
            currentYear = serie.data.map((e) => Number(e));
          } else if (serie.name === 'Receita Ano Passado') {
            lastYear = serie.data.map((e) => Number(e));
          }
        });

        if (e.xaxis.name === 'Tempo') {
          const meses = [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Out',
            'Nov',
            'Dez',
          ];
          const data = e.xaxis.data.map((dt) => {
            let dtf = new Date(dt);
            return meses[dtf.getMonth()];
          });
          totalDates = data;
        }

        let arrayOrder = [];
        var i = 0;
        for (; i < currentYear.length; i++) {
          arrayOrder.push({
            Atual: currentYear.find((_, index) => index == i),
            Passado: lastYear.find((_, index) => index == i),
            name: totalDates.find((_, index) => index == i),
          });
        }

        this.monthlyBilling.data = arrayOrder;
      } else if (e.name === 'Faturamento Diário') {
        let revenue = [];
        let totalDays = [];
        e.series.map((serie, index) => {
          if (serie.name === 'Receita Dia a Dia Mês Atual') {
            revenue = serie.data.map((e) => Number(e));
          }
        });

        if (e.xaxis.name === 'Tempo') {
          const data = e.xaxis.data.map((dt) => {
            let dtf = new Date(dt);
            return dtf.getDate() + '/' + (dtf.getMonth() + 1);
          });
          totalDays = data;
        }

        let arrayOrder = [];
        var i = 0;
        for (; i < revenue.length; i++) {
          arrayOrder.push({
            R$: revenue.find((_, index) => index == i),
            name: totalDays.find((_, index) => index == i),
          });
        }

        this.dailyBilling.data = arrayOrder;
      } else if (e.name === 'Taxas de conversão e adição ao carrinho') {
        let cart = [];
        let conversion = [];
        let totalDates = [];
        e.series.map((serie, index) => {
          if (serie.name === 'carrinho') {
            cart = serie.data;
          } else if (serie.name === 'conversão') {
            conversion = serie.data;
          }
        });

        if (e.xaxis.name === 'Tempo') {
          const meses = [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Out',
            'Nov',
            'Dez',
          ];
          const data = e.xaxis.data.map((dt, i) => {
            return meses[i];
          });
          totalDates = data;
        }

        let arrayOrder = [];
        var i = 0;
        for (; i < cart.length; i++) {
          arrayOrder.push({
            Carrinho: Number(cart.find((_, index) => index == i)),
            Conversão: Number(conversion.find((_, index) => index == i)),
            name: mesAlias(e.xaxis.data.map((mes) => mes.substring(0, 3))[i]),
          });
        }

        this.conversionRatesAndAddtoCart.data = arrayOrder;
      }
    });
  }
}
export default DashboardStore;

const mesAlias = (month) => {
  switch (month) {
    case 'Jan':
      return 'Jan';
    case 'Feb':
      return 'Fev';
    case 'Mar':
      return 'Mar';
    case 'Apr':
      return 'Abr';
    case 'May':
      return 'Mai';
    case 'Jun':
      return 'Jun';
    case 'Jul':
      return 'Jul';
    case 'Aug':
      return 'Ago';
    case 'Dec':
      return 'Dez';
    case 'Sep':
      return 'Set';
    case 'Oct':
      return 'Out';
    case 'Nov':
      return 'Nov';
    default:
      return '';
  }
};
