import BaseAPI, { URLS } from './BaseAPI';

class ShippingAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(`${URLS.SHIPPING}/search`, params);
      return response.status === 200
        ? response.data
        : { error: response?.data?.message
          ? response?.data?.message
          : 'Falha ao carregar a lista de fretes' };
    } catch (e) {
      console.log(e.message);
      return { error: e?.response?.data?.message
        ? e?.response?.data?.message
        : 'Falha ao carregar a lista de fretes' };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.SHIPPING, data);
      return response.status === 201
        ? response.data
        : { error: response?.data?.message
          ? response?.data?.message
          : 'Falha ao salvar frete' };
    } catch (e) {
      return { response: e.response, error: e?.response?.data?.message
        ? e?.response?.data?.message
        : 'Falha ao salvar frete' };
    }
  }

  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.SHIPPING}/${uuid}`, data);
      return response.status === 200
        ? response.data
        : { error: response?.data?.message
          ? response?.data?.message
          : 'Falha ao atualizar frete' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar frete',
      };
    }
  }

  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.SHIPPING}/${uuid}`);
      return response.status === 200
        ? response.data
        : { error: response?.data?.message
          ? response?.data?.message
          : 'Falha ao carregar frete' };
    } catch (e) {
      console.log(e.message);
      return { error: e?.response?.data?.message
        ? e?.response?.data?.message
        : 'Falha ao carregar frete' };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.SHIPPING}/${uuid}`);
      return response.status === 204
        ? response.data
        : { error: response?.data?.message
          ? response?.data?.message
          :  'Falha ao excluir frete' };
    } catch (e) {
      console.log(e.message);
      return { error: e?.response?.data?.message
        ? e?.response?.data?.message
        :  'Falha ao excluir frete' };
    }
  }
}

export default ShippingAPI;
