/* eslint-disable class-methods-use-this */
import { extendObservable } from 'mobx';

import StorageUtil, { KEYS } from '~/helpers/utils/StorageUtil';
import ItemMenuModel from '~/models/ItemMenuModel';
import BannersPermissionEnum from '~/helpers/utils/permissions/banner';
import CategoriesPermissionEnum from '~/helpers/utils/permissions/categories';
import CustomerPermissionEnum from '~/helpers/utils/permissions/customer';
import OrdersPermissionEnum from '~/helpers/utils/permissions/orders';
import PromotionPermissionEnum from '~/helpers/utils/permissions/promotion';
import ProductsPermissionEnum from '~/helpers/utils/permissions/products';
import ReportsPermissionEnum from '~/helpers/utils/permissions/reports';
import ColorsPermissionEnum from '~/helpers/utils/permissions/colors';
import AccountsPermissionEnum from '~/helpers/utils/permissions/accounts';
// import AuthoritiesPermissionEnum from "~/helpers/utils/permissions/authorities";
import AuthoritiesGroupsPermissionEnum from '~/helpers/utils/permissions/authoritiesGroups';
import VoltagesPermissionEnum from '~/helpers/utils/permissions/voltages';
import UnitsPermissionEnum from '~/helpers/utils/permissions/units';
import SizesPermissionEnum from '~/helpers/utils/permissions/sizes';
import PropertiesPermissionEnum from '~/helpers/utils/permissions/properties';
import MaterialsPermissionEnum from '~/helpers/utils/permissions/materials';
import ComponentsPermissionEnum from '~/helpers/utils/permissions/components';
import ShippingPermissionEnum from '~/helpers/utils/permissions/shipping';
import ChannelsPermissionEnum from '~/helpers/utils/permissions/channels';
import SubChannelsPermissionEnum from '~/helpers/utils/permissions/subChannels';
import CampainsPermissionEnum from '../helpers/utils/permissions/campains';
import VoucherBatchesPermissionEnum from '../helpers/utils/permissions/voucherBatches';
import FaviconPermissionEnum from '../helpers/utils/permissions/favicon';
import LogoPermissionEnum from '../helpers/utils/permissions/logo';
import EvaluationsPermissionEnum from '../helpers/utils/permissions/evaluations';
import TemplateEmailPermissionEnum from '../helpers/utils/permissions/emailTemplate';
import PaymentPermissionEnum from '../helpers/utils/permissions/payment';
import reportVouchersEnum from '../helpers/utils/permissions/reportVouchers';
import reportCampaignsEnum from '../helpers/utils/permissions/reportCampaigns';
import reportBestSellingCategoriesEnum from '../helpers/utils/permissions/reportBestSellingCategories';
import reportAbandonedCartsEnum from '../helpers/utils/permissions/reportAbandonedCarts';
import ReportSalesGeneralPermissionEnum from '../helpers/utils/permissions/reportSalesGeneral';
import ReportTopViewCategoriesEnum from '../helpers/utils/permissions/reportTopViewCategories';
import reportBestSellingProductsEnum from '../helpers/utils/permissions/reportBestSellingProducts';
import shippingCSVEnum from '../helpers/utils/permissions/shippingCSV';
import reportLog from '../helpers/utils/permissions/reportLog';
import StampsPermissionEnum from '../helpers/utils/permissions/stamps';
import InstitucionalPermissionEnum from '../helpers/utils/permissions/institucional';

// const ManagerPropertyContainer = lazy(() => import('../containers/ManagerPropertyContainer'));

class MenuStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, {
      menus: [],
      dashboard: new ItemMenuModel(this.dashboardData),
      itemMenu: undefined,
      subItemMenu: undefined,
    });
  }

  /** Carrega menu */
  loadMenu() {
    const showMenuStoom = this.rootStore.authStore.isStoomAdmin;
    let items = this.itemsMenu;

    if (!showMenuStoom) {
      items = this.itemsMenu.filter((item) => item.title !== 'Stoom');
    }

    this.menus = items.map((item) => new ItemMenuModel(item));
    if (StorageUtil.getItem(KEYS.PATH_MENU)) {
      this.setCurrentMenu(StorageUtil.getItem(KEYS.PATH_MENU));
    }
  }

  /** Altera o menu atual. */
  setCurrentMenu(path) {
    StorageUtil.setItem(KEYS.PATH_MENU, path);
    this.itemMenu = this.menus.find((item) => {
      const sub = item.subItems.find((subItem) => subItem.path === path);
      if (sub) {
        this.subItemMenu = sub;
        return item;
      }
      return null;
    });
  }

  /** Retorna o titulo do menu selecionado */
  get title() {
    if (!this.itemMenu) return this.dashboard.title;
    return this.itemMenu && this.itemMenu.title;
  }

  /** Retorna o subtitulo do submenu */
  get subtitle() {
    if (!this.subItemMenu) return this.dashboard.subtitle;
    return this.subItemMenu && this.subItemMenu.title;
  }

  /** Retorna se dashboard está visible */
  get dashboardVisible() {
    return !this.itemMenu;
  }

  /** Dados da dashboard */
  get dashboardData() {
    return {
      title: 'Dashboard',
      subtitle: 'Um painel completo para controlar seu site',
      icon: 'fas fa-chart-bar',
      path: '/dashboard',
      subItems: [],
    };
  }

  /** Items de menu */
  get itemsMenu() {
    return Object.values({
      usuarios: {
        title: 'Administradores',
        subtitle: 'Área de administração de Usúarios',
        icon: 'fas fa-user-shield',
        permissions: Object.values(AccountsPermissionEnum).concat(
          Object.values(AuthoritiesGroupsPermissionEnum)
        ),
        subItems: [
          {
            path: '/usuarios',
            title: 'Usuários',
            permissions: Object.values(AccountsPermissionEnum),
          },
          {
            path: '/perfil',
            title: 'Perfis',
            permissions: Object.values(AuthoritiesGroupsPermissionEnum),
          },
        ],
      },
      banners: {
        title: 'Banners',
        subtitle: 'Área de Banners',
        icon: 'fas fa-light fa-image',
        permissions: Object.values(BannersPermissionEnum),
        subItems: [
          {
            path: '/banners',
            title: 'Banners',
            permissions: Object.values(BannersPermissionEnum),
          },
        ],
      },
      categorias: {
        title: 'Categorias',
        icon: 'fas fa-th',
        permissions: Object.values(CategoriesPermissionEnum),
        subItems: [
          {
            path: '/categorias',
            title: 'Categorias',
            permissions: Object.values(CategoriesPermissionEnum),
          },
        ],
      },
      clientes: {
        title: 'Clientes',
        icon: 'fas fa-users',
        permissions: Object.values(CustomerPermissionEnum)
          .concat(Object.values(ChannelsPermissionEnum))
          .concat(Object.values(SubChannelsPermissionEnum)),
        subItems: [
          {
            path: '/clientes',
            title: 'Clientes',
            permissions: Object.values(CustomerPermissionEnum),
          },
          {
            path: '/canal',
            title: 'Canal',
            permissions: Object.values(ChannelsPermissionEnum),
          },
          {
            path: '/sub-canal',
            title: 'Sub Canal',
            permissions: Object.values(SubChannelsPermissionEnum),
          },
        ],
      },
      configs: {
        title: 'Configurações loja',
        subtitle: 'Área de Configurações da loja',
        icon: 'fas fa-light fa-cog',
        permissions: Object.values(StampsPermissionEnum)
          .concat(Object.values(FaviconPermissionEnum))
          .concat(Object.values(LogoPermissionEnum)),
        subItems: [
          {
            path: '/configuracoes-loja',
            title: 'Configurações loja',
            permissions: Object.values(FaviconPermissionEnum)
              .concat(Object.values(LogoPermissionEnum)),
          },
          {
            path: '/institucional',
            title: 'Institucional',
            permissions: Object.values(InstitucionalPermissionEnum),
          },
          {
            path: '/selos-footer',
            title: 'Selos',
            permissions: Object.values(StampsPermissionEnum),
          },
        ],
      },
      installments: {
        title: 'Condições de Pagamento',
        subtitle: 'Condições de Pagamento',
        icon: 'fas fa-solid fa-credit-card',
        permissions: Object.values(PaymentPermissionEnum),
        subItems: [
          {
            path: '/condicoes-parcelamento',
            title: 'Configurar parcelas',
            permissions: Object.values(PaymentPermissionEnum),
          },
        ],
      },
      shipping: {
        title: 'Fretes',
        subtitle: 'Fretes',
        icon: 'fas fa-light fa-truck',
        permissions: Object.values(ShippingPermissionEnum),
        subItems: [
          {
            path: '/fretes',
            title: 'Fretes',
            permissions: Object.values(ShippingPermissionEnum),
          },
          {
            path: '/upload-frete',
            title: 'Upload Frete',
            permissions: Object.values(ShippingPermissionEnum),
          },
        ],
      },
      home: {
        title: 'Home',
        subtitle: 'Área de Home',
        icon: 'fas fa-light fa-home',
        permissions: Object.values(ComponentsPermissionEnum),
        subItems: [
          {
            path: '/home',
            title: 'Home',
            permissions: Object.values(ComponentsPermissionEnum),
          },
        ],
      },
      promotion: {
        title: 'Promoções',
        subtitle: 'Promoções',
        icon: 'fas fa-light fa-home',
        permissions: Object.values(CampainsPermissionEnum).concat(
          Object.values(VoucherBatchesPermissionEnum)
        ),
        subItems: [
          {
            path: '/campanhas',
            title: 'Campanhas',
            permissions: Object.values(CampainsPermissionEnum),
          },
          {
            path: '/cupons',
            title: 'Cupons',
            permissions: Object.values(VoucherBatchesPermissionEnum),
          },
        ],
      },
      order: {
        title: 'Pedidos',
        subtitle: 'Pedidos',
        icon: 'fas fa-light fa-home',
        permissions: Object.values(OrdersPermissionEnum),
        subItems: [
          {
            path: '/pedidos',
            title: 'Pedidos',
            permissions: Object.values(OrdersPermissionEnum),
          },
        ],
      },
      produtos: {
        title: 'Produtos',
        icon: 'fab fa-product-hunt',
        permissions: Object.values(ProductsPermissionEnum)
          .concat(Object.values(ColorsPermissionEnum))
          .concat(Object.values(SizesPermissionEnum))
          .concat(Object.values(VoltagesPermissionEnum))
          .concat(Object.values(PropertiesPermissionEnum))
          // .concat(Object.values(EvaluationsPermissionEnum))
          .concat(Object.values(MaterialsPermissionEnum)),
        subItems: [
          {
            path: '/produtos/MODEL',
            title: 'Produtos',
            permissions: Object.values(ProductsPermissionEnum),
          },
          {
            path: '/cadastrar-variacao-produtos',
            title: 'Produto Variação',
            permissions: Object.values(ProductsPermissionEnum),
          },
          {
            path: '/cores',
            title: 'Cores',
            permissions: Object.values(ColorsPermissionEnum),
          },
          {
            path: '/tamanhos',
            title: 'Tamanhos',
            permissions: Object.values(SizesPermissionEnum),
          },
          {
            path: '/voltagem',
            title: 'Voltagens',
            permissions: Object.values(VoltagesPermissionEnum),
          },
          {
            path: '/material',
            title: 'Material',
            permissions: Object.values(MaterialsPermissionEnum),
          },
          {
            path: '/gerenciar-avaliacoes',
            title: 'Avaliações',
            permissions: Object.values(EvaluationsPermissionEnum),
          },
          {
            path: '/propriedades',
            title: 'Especificações',
            permissions: Object.values(PropertiesPermissionEnum),
          },
        ],
      },
      sales: {
        title: 'Relatórios',
        subtitle: 'Um painel completo para controlar seu site',
        icon: 'fas fa-clone',
        permissions: Object.values(reportVouchersEnum)
          .concat(Object.values(reportCampaignsEnum))
          .concat(Object.values(reportBestSellingCategoriesEnum))
          .concat(Object.values(ReportSalesGeneralPermissionEnum))
          .concat(Object.values(reportBestSellingProductsEnum))
          .concat(Object.values(ReportTopViewCategoriesEnum))
          .concat(Object.values(reportAbandonedCartsEnum))
          .concat(Object.values(reportLog))
          .concat(Object.values(EvaluationsPermissionEnum)),
        subItems: [
          {
            path: '/vendas/general',
            title: 'Visão geral',
            permissions: Object.values(ReportSalesGeneralPermissionEnum),
          },
          {
            path: '/vendas/carrinho-abandonado',
            title: 'Carrinho abandonado',
            permissions: Object.values(reportAbandonedCartsEnum),
          },
          {
            path: '/vendas/produto',
            title: 'Vendas por produto',
            permissions: Object.values(reportBestSellingProductsEnum),
          },
          {
            path: '/vendas/produtos-mais-vistos',
            title: 'Produtos mais vistos',
            permissions: Object.values(ReportTopViewCategoriesEnum),
          },
          {
            path: '/vendas/avaliacao-produtos',
            title: 'Avaliação por produto',
            permissions: Object.values(EvaluationsPermissionEnum),
          },
          {
            path: '/vendas/categorias',
            title: 'Vendas por Categoria',
            permissions: Object.values(reportBestSellingCategoriesEnum),
          },
          {
            path: '/vendas/campanha',
            title: 'Campanha',
            permissions: Object.values(reportCampaignsEnum),
          },
          {
            path: '/vendas/cupom',
            title: 'Cupom',
            permissions: Object.values(reportVouchersEnum),
          },
          {
            path: '/vendas/logs',
            title: 'Log de Acesso',
            permissions: Object.values(reportLog),
          },
        ],
      },
      templates: {
        title: 'Templates e-mail',
        subtitle: 'Área de templates de e-mail',
        icon: 'fas fa-light fa-envelope',
        permissions: Object.values(TemplateEmailPermissionEnum),
        subItems: [
          {
            path: '/lista-templates',
            title: 'Lista de Templates',
            permissions: Object.values(TemplateEmailPermissionEnum),
          },
        ],
      },
      // users: {
      //   title: 'Usuários',
      //   subtitle: 'Área de usuários',
      //   icon: 'fas fa-light fa-user',
      //   subItems: [
      //     { path: '/usuarios', title: 'Usuários' },
      //     { path: '/cadastrar-usuario', title: 'Novo usuário' },
      //     { path: '/grupos-usuario', title: 'Grupos' },
      //     { path: '/cadastrar-grupo-usuario', title: 'Novo grupo' },
      //        //Criação de Grupo
      //        { path: "/perfil", title: "Perfis" },
      //        { path: "/novo-perfil", title: "Novo Perfil" },
      //   ],
      // },
      // stoom: {
      //   title: 'Stoom',
      //   subtitle: 'Área Staff',
      //   icon: 'fas fa-hammer',
      //   subItems: [
      //     { path: '/merchants', title: 'Merchants' },
      //     { path: '/cadastrar-merchant', title: 'Novo Merchant' },
      //   ],
      // },
    });
  }
}

export default MenuStore;
