import { extendObservable } from 'mobx';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import TemplateEmailAPI from '../services/TemplateEmailAPI';
import TemplateModel from '../models/TemplateModel';
import { substituirFiltrosCurrency } from '../helpers/utils/Functions';

class TemplateStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      template: undefined,
      loading: false,
      superAdmin: false,
      templates: [],
      jsonExample: undefined,
      totalPages: 0,
      to: [],
      size: 20,
      page: 0,
      sort: 'type',
    });
  }

  reset() {
    this.template = undefined;
    this.totalPages = 0;
    this.size = 20;
    this.number = 0;
    this.sort = 'created';
    this.loading = false;
  }

  /**Atualiza uma propriedade da nova unidade */
  async onChange(prop, value) {
    const template = this.template;
    switch (prop) {
      case 'body':
        this.template.body = value;
        break;
      default:
        template[prop] = value;
    }
  }

  async getJsonInformation(prop, value) {
    this.jsonExample = value;
    return value;
  }

  /**Busca todos os usuarios addmins */
  async getList(size = 15, page = this.page, sort = this.sort) {
    this.loading = true;

    const params = {
      search: 'example:false',
      size: this.size,
      page: this.page,
      sort: this.sort,
    };

    const response = await TemplateEmailAPI.getList(params);


    // Lista de tipos que você deseja remover
    const typesToRemove = ["RETURNED", "CART", "SEPARATED", "WELCOME"];

    // Filtrando o array para remover os itens indesejados
    const filteredArray = response.content.filter(item => !typesToRemove.includes(item.type));


    this.loading = false;
    if (response.error) return [];
    this.templates = filteredArray.map((value) => new TemplateModel(value));
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  async get(uuid) {
    this.loading = true;
    const response = await TemplateEmailAPI.get(uuid);
    this.loading = false;
    if (!response.error) {
      this.template = new TemplateModel(response);
      return this.template;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async getDataJson(uuid) {
    this.loading = true;
    const response = await TemplateEmailAPI.getDataJson(uuid);
    if (response.content.length > 0) {
      this.jsonExample = JSON.parse(response?.content[0]?.body);
    } else {
      this.jsonExample = [];
    }
    this.loading = false;
    if (!response.error) {
      return response;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async sendEmailTesting(json) {
    const sendEmail = {
      to: [this.template?.to],
      subject: this.template?.subject,
      data: {
        ...json,
        html: substituirFiltrosCurrency(this.template?.body),
      },
    };

    const response = await TemplateEmailAPI.sendEmailTesting(sendEmail);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'E-mail enviado com sucesso!'
      );
      return this.template;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async updateTemplate(uuid) {
    this.loading = true;
    const uuidValue = this.template.uuid;

    this.template.body = substituirFiltrosCurrency(this.template.body);

    const response = await TemplateEmailAPI.updateTemplate(
      this.template,
      uuidValue
    );
    this.loading = false;
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Email salvo com sucesso');
      return this.template;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    const params = {
      search: 'example:false',
      size: 1000,
      page: 0,
      sort: this.sort,
    };

    const response = await TemplateEmailAPI.getList(params);

    // Lista de tipos que você deseja remover
    const typesToRemove = ["RETURNED", "CART", "SEPARATED", "WELCOME"];

    // Filtrando o array para remover os itens indesejados
    const filteredArray = response.content.filter(item => !typesToRemove.includes(item.type));

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return filteredArray.map((value) => {
      return {
        active: value.active,
        created: value.created,
        deleted: value.deleted,
        example: value.example,
        modified: value.modified,
        subject: value.subject,
        type: value.type,
        uuid: value.uuid,
      };
    });
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, this.sort);
  }

  async setPage(page) {
    this.page = page;
    await this.getList(this.size, page);
  }
}
export default TemplateStore;
