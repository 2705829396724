import BaseModel from './BaseModel';

class AuthoritiesModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.uuid = data.uuid;
    this.name = data.name;
    this.key = data.key;
    this.permitAll = data.permitAll;
    this.action = data.action;
    this.pattern = data.pattern;
    this.description = data.description;
    this.show = data.show;
    this.groupedBy = data.groupedBy;
    this.authorities = data.authorities
      ? data.authorities.map((value) => new AuthoritiesModel(value))
      : [];
  }
}

export default AuthoritiesModel;
