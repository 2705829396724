import { extendObservable } from 'mobx';
import BaseModel from './BaseModel';
import DiscountModel from './DiscountModel';

class CampaignModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.name = data.name;
    this.type = data.type;
    this.bean = data.bean;
    if (data.discounts) {
      this.discounts = data.discounts.map(
        (discount) => new DiscountModel(discount)
      );
    } else {
      this.discounts = [new DiscountModel()];
    }
    this.restrictions = data.restrictions || [];
    // this.fixedPrice = new FixedPrice(data.fixedPrice);
    this.mobileBanner = data.mobileBanner;
    this.excludes = data.excludes;


    extendObservable(this, {
      products: data.products,
    });
  }
}

export default CampaignModel;

class FixedPrice {
  constructor(data) {
    this['@class'] = 'br.com.stoom.kernel.model.persistence.FixedPrice';
    this.active = data?.active;
    this.created = data?.created;
    this.deleted = data?.deleted;
    this.fixedShippingPrice = data?.fixedShippingPrice;
    this.modified = data?.modified;
    this.uuid = data?.uuid;
  }
}
export { FixedPrice };
