import { extendObservable } from 'mobx';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import { cryptoPass } from '~/helpers/utils/Functions';
import AdministratorModel from '~/models/AdministratorModel';
import AuthoritiesModel from '~/models/AuthoritiesModel';
import AuthorityGroups from '~/models/AuthorityGroupsModel';
import PerfilModel from '~/models/PerfilModel';
import PerfilAPI from '~/services/PerfilAPI';

const initValues = {
  loading: false,
  perfil: {},
  administrator: [],
  authority: [],
  perfilList: [],
  authorizations: [],
  userList: [],
  listCheckbox: [],
  checkboxAuthorizations: [],
  excludeAuthorities: [],
  addAuthorities: [],
  totalPages: 0,
  size: 20,
  page: 0,
  sort: 'name',
  listChecked: [],
  perfilInput: [],
  user: {},
  test: [],
  authoritiesGroup: [],
};

/** Store que manipula dados de merchants */
class PerfilStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
    this.perfil = new PerfilModel();
    this.authorizations = new AuthoritiesModel();
    this.administrator = new AdministratorModel();
    this.checkboxAuthorizations = [];
  }

  reset() {
    this.totalPages = 0;
    this.page = 0;
    this.size = 10;
    this.excludeAuthorities = [];
    this.addAuthorities = [];
    this.listChecked = [];
    this.sort = 'created,desc';
    this.perfil = new PerfilModel();
    this.authorizations = new AuthoritiesModel();
    this.checkboxAuthorizations = [];
  }

  ///////// GRUPO /////////

  /**Função que atribui o valor do campo no model */
  updateProp(prop, value) {
    const { perfil } = this;
    perfil[prop] = value;
    this.perfil = new PerfilModel(perfil);
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getPerfil();
  }

  updateCheckBoxProp(prop, value) {
    const { authorizations, perfil, administrator } = this;
    authorizations[prop] = value;
    this.authorizations = authorizations;

    let verifyList = perfil.authorities.filter(
      (i) => i.uuid === authorizations.uuid
    );

    if (verifyList?.length > 0) {
      const removeItem = perfil.authorities.filter(
        (i) => i.uuid !== authorizations.uuid
      );

      perfil.authorities = removeItem;
    } else {
      perfil.authorities.push(authorizations);
      this.authorizations = new AuthoritiesModel();
    }
  }


  updateMultipleCheckbox(prop, uuid, value) {
    const filterList = this.listChecked.filter((item) => item === value);

    if(filterList.length > 0) {
      //Remove os itens da lista
      // this.listChecked = this.listChecked.filter((a) => a !== uuid);
    } else {
      //Adiciona os itens na lista
      const duplicateItem = [...this.listChecked, uuid];

      //Remove os itens duplicados
      const listaSemDuplicatas = duplicateItem.filter((valor, indice, array) => {
        return array.indexOf(valor) === indice;
      });

      this.listChecked = listaSemDuplicatas
    }
  }


  updateCheckBoxAdministradorProp(prop, value) {
    const { authorizations, perfil, administrator } = this;
    authorizations[prop] = value;
    this.authorizations = authorizations;

    let verifyList = administrator.user.authorities.filter(
      (i) => i.uuid === authorizations.uuid
    );

    if (this.listChecked.includes(value)) {
      this.listChecked = this.listChecked.filter((a) => a !== value);
    } else {
      this.listChecked = [...this.listChecked, value];
    }

    if (verifyList.length > 0) {
      const removeItem = administrator.user.authorities.filter(
        (i) => i.uuid !== authorizations.uuid
      );
      administrator.user.authorities = removeItem;

      // Adiciona a lista de exclusão
      this.excludeAuthorities.push(authorizations);

      //Remove os itens da lista de Adição
      const removeAddItem = this.addAuthorities.filter(
        (i) => i.uuid !== authorizations.uuid
      );
      this.addAuthorities = removeAddItem;

      this.authorizations = new AuthoritiesModel();
    } else {
      //Remove o item da lista de exclusão
      const removeItem = this.excludeAuthorities.filter(
        (i) => i.uuid !== authorizations.uuid
      );

      this.excludeAuthorities = removeItem;

      //Adiciona os itens da lista de Adição

      this.addAuthorities.push(authorizations);
      administrator.user.authorities.push(authorizations);
      this.authorizations = new AuthoritiesModel();
    }
  }

  /** Busca lista de autorizações */
  async getListAuthorities() {
    this.loading = true;
    const response = await PerfilAPI.getListAuthorities();
    this.loading = false;
    if (!response.error) {
      this.authority = response.content.map((i) => new AuthoritiesModel(i));
      // this.authoritiesGroup = groupNamesBySimilarFirstWord(response);

      return this.authority;
    }
  }

  /** Busca lista os Grupos de usuario */
  async getPerfil() {
    this.loading = true;

    const params = {
      size: this.size,
      page: this.page,
      sort: this.sort,
    }

    const response = await PerfilAPI.getPerfil(params);
    if (!response.error) {
      this.perfilList = response.content.map((i) => new AuthorityGroups(i));
      this.loading = false;
      return this.perfilList;
    }
    this.loading = false;
    return response;
  }

  /** Busca perfil por uuid */
  async getUuidPerfil(uuid) {
    this.loading = true;
    const response = await PerfilAPI.getUuidPerfil(uuid);
    if (!response.error) {
      this.perfil = new PerfilModel(response);
      this.listChecked = response.authorities.map((i) => i.uuid);
      return response;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async selectedAuthorities(prop, item) {
    this.listChecked = item.authorities.map((i) => i.uuid);
    this.administrator.user.authorities = item.authorities;

    this.administrator.user.authorityGroups = [
      {
        uuid: item.value,
      },
    ];
  }

  /**Cria um novo usuário (cliente) */
  async createPerfil() {
    this.loading = true;
    const data = this.perfil;
    this.perfil.authorities = this.listChecked.map((uuid) => ({ uuid }));
    const response = await PerfilAPI.createPerfil(data);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Perfil criado com sucesso'
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  /**Atualiza os campos da loja */
  async updatePerfil(uuid) {
    this.loading = true;

    const data = this.perfil;
    this.perfil.authorities = this.listChecked.map((uuid) => ({ uuid }));
    const response = await PerfilAPI.updatePerfil(data, uuid);

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Alterações gravadas com sucesso.'
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  // Deleta os usuarios
  async delete(uuid) {
    this.loading = true;
    const response = await PerfilAPI.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Perfil excluído com sucesso'
      );
      await this.getPerfil();
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  ///////// USUARIO /////////

  /**Atualiza uma propriedade do novo adminstrator*/
  updateUserProp(prop, value) {
    const { perfil } = this;
    perfil[prop] = value;
    this.perfil = new AdministratorModel(perfil);
  }

  /**Busca todos os usuarios addmins */
  async getUser(size = 15, page = this.page, sort = this.sort) {
    this.loading = true;
    const response = await PerfilAPI.getUser({
      size,
      page,
      sort,
    });
    this.loading = false;
    if (response.error) return [];
    this.userList = response.content.map((usr) => new AdministratorModel(usr));

    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 20, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = 'name';

    sort ? (this.sort = sort) : (this.sort = 'name');

    await this.getUser();
    return true;
  }

  /** Busca usuarios por uuid */
  async getUuidUser(uuid) {
    this.loading = true;
    const response = await PerfilAPI.getUuidUser(uuid);
    if (!response.error) {
      this.administrator = new AdministratorModel(response);
      this.listChecked = response?.user?.authorityGroups[0].authorities?.map(
        (i) => i.uuid
      );

      this.loading = false;
      return response;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Atualiza uma propriedade do novo usuario*/
  async updatePropAdministrator(prop, value) {
    const account = this.administrator
      ? this.administrator
      : new AdministratorModel();
    switch (prop) {
      case 'username':
        account.user[prop] = value;
        break;
      case 'email':
        account.user[prop] = value;
        break;
      case 'state':
        account.user[prop] = value;
        break;
      case 'pass':
        account.user.password = await cryptoPass(value);
        break;
      case 'merchant':
        account.merchant = value;
        break;
      default:
        account[prop] = value;
    }
    this.administrator = new AdministratorModel(account);
  }

  updateDataInitial() {
    this.administrator = new AdministratorModel();
  }

  /** Busca usuario por nome */
  async getUserByName() {
    const response = await PerfilAPI.getUserByName();
    if (!response.error) {
      this.perfilList = response;
      return this.perfilList;
    }
    return response;
  }

  // Atualiza as opções de checkbox para usuario
  updateCheckBoxUserProp(prop, value, data) {
    const { authorizations, administrator } = this;
    authorizations[prop] = value;
    this.authorizations = authorizations;

    let verifyUuid = administrator.user.authorities.filter(
      (i) => i.uuid === authorizations.uuid
    );

    const idx = administrator.user.authorities.indexOf(verifyUuid);

    if (administrator.user.authorities.length > 0) {
      if (verifyUuid.length > 0) {
        administrator.user.authorities.splice(idx);
        this.listCheckbox.splice(idx);
        return false;
      } else {
        administrator.user.authorities.push(authorizations);
        this.authorizations = new AdministratorModel();
        this.listCheckbox.push(authorizations);
        return true;
      }
    } else {
      administrator.user.authorities.push(authorizations);
      this.authorizations = new AdministratorModel();
      this.listCheckbox.push(authorizations);
    }
  }

  // Atualiza as opções de checkbox para usuario
  updateCheckBoxGroupProp(data) {
    const { authorizations, administrator } = this;
    this.authorizations = authorizations;
    administrator.user.authorityGroups.push(data);
  }

  // Atualiza as opções de checkbox para usuario
  updateResponsibleProp(value) {
    const { administrator } = this;
    const account = this.administrator
      ? this.administrator
      : new AdministratorModel();

    administrator.responsibleAdministrator = value;

    this.administrator = new AdministratorModel(account);
  }

  /**Atualiza os campos do usuario */
  async updateAdministrator(uuid) {
    this.loading = true;

    this.administrator.user.password = 'temp';
    const data = this.administrator;
    const groupUuid = this.administrator.user.authorityGroups[0].uuid;
    const userUuid = this.administrator.user.uuid;

    const response = await PerfilAPI.updateAdministrator(
      data,
      groupUuid,
      userUuid
    );

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Alterações gravadas com sucesso.'
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  /**Atualiza os campos do usuario */
  async createAdministrator() {
    this.loading = true;

    const data = this.administrator;
    const response = await PerfilAPI.createAdministrator(data);

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Alterações gravadas com sucesso.'
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  async getResponsible(name) {
    const response = await PerfilAPI.getResponsible(name);

    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);

    return response;
  }

  /**Deleta administrador */
  async deleteAdministrator(uuid) {
    this.loading = true;
    const response = await PerfilAPI.deleteAdministrator(uuid);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Administrador excluído com sucesso!'
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async getListPerfil() {
    this.loading = true;
    const response = await PerfilAPI.getPerfil();

    if (response.error) {
      this.perfilInput = [];
      return;
    }

    this.perfilInput = response.content.map((item) => ({
      value: item.uuid,
      label: item.name,
      authorities: item.authorities,
    }));
    this.loading = false;
  }

  async getExpoxtListPerfil(size = 1000, page = this.page, sort = this.sort) {
    const response = await PerfilAPI.getPerfil({
      size,
      page,
      sort,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content.map((i) => new AuthorityGroups(i));
  }
}

export default PerfilStore;
