const MaterialsPermissionEnum = Object.freeze({
  POST: {
    pattern: '/manager/material',
    action: 'POST',
  },
  PUT: {
    pattern: '/manager/material/**',
    action: 'PUT',
  },
  DELETE: {
    pattern: '/manager/material/**',
    action: 'DELETE',
  },
  GET: {
    pattern: '/manager/material',
    action: 'GET',
  },
  GET_UUID: {
    pattern: '/manager/material/**',
    action: 'GET',
  },
});

export default MaterialsPermissionEnum;
