import BaseAPI, { URLS } from './BaseAPI';

class MaterialAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(URLS.MATERIAL, params);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar a lista de materiais' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar a lista de materiais' };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.MATERIAL, data);
      return response.status === 201
        ? response.data
        : { error: 'Falha ao salvar material' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar material',
      };
    }
  }

  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.MATERIAL}/${uuid}`, data);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao atualizar material' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar material',
      };
    }
  }

  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.MATERIAL}/${uuid}`);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar material' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar material' };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.MATERIAL}/${uuid}`);
      return response.status === 204
        ? response.data
        : { error: 'Falha ao excluir material' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao excluir material' };
    }
  }
}

export default MaterialAPI;
