import BaseModel from './BaseModel';
import MerchantModel from './MerchantModel';
import CategoryModel from './CategoryModel';

class OurStoreModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;

    this.merchant = data.merchant && new MerchantModel(data.merchant);
    // this.branch = data.branch && new BranchModel(data.branch);

    this.brandImage = data.brandImage;
    this.description = data.description;
    this.name = data.name;
    this.cnpj = data.cnpj;
    this.email = data.email;
    this.addressComplement = data.addressComplement;
    this.neighborhood = data.neighborhood;
    this.city = data.city;
    this.phone = data.phone;
    this.state = data.state;
    this.address = data.address;
    this.showBrandHome =
      data.showBrandHome !== undefined ? data.showBrandHome : false;
    this.cep = data.cep;
    this.country = 'Brasil';

    this.latitude = data.latitude;
    this.longitude = data.longitude;

    this.files = [];

    this.businessHours = data.businessHours;
    this.holidayOpeningHours = data.holidayOpeningHours;
    this.openingHours = data.openingHours;
    this.saturdayOpeningHours = data.saturdayOpeningHours;
    this.sundayOpeningHours = data.sundayOpeningHours;
    this.category = data.category && new CategoryModel(data.category);
  }
}

export default OurStoreModel;
