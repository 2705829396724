import { extendObservable } from 'mobx';

import API from '../services/ShippingAPI';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import { isArrayEmpty, onlyUnique } from '~/helpers/utils/Functions';

import ShippingModel from '~/models/ShippingModel';

class ShippingStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      shipping: undefined,
      shippings: [],
      shippingsUpload: [],
      shippingsUploadTemp: [
        {
          shippingCompany: 'STAC',
          orgVendas: 'BEL - BELÉM',
          regVendas: 'CAP - CAPITAL',
          corte: '2023-05-15T17:30:00.000+00:00',
          sla: 3,
          uuid: 0,
          hour: '17:30:00',
          status: 2,
          slaView: 3,
        },
        {
          shippingCompany: 'STAC',
          orgVendas: 'BEL - BELÉM',
          regVendas: 'INT - INTERIOR',
          corte: '2023-05-15T18:00:00.000+00:00',
          sla: 1,
          uuid: 1,
          hour: '18:00:00',
          status: 2,
          slaView: 1,
        },
        {
          shippingCompany: 'STAC',
          orgVendas: 'BEL - BELÉM',
          regVendas: 'INT1X - INTERIOR DIFERENTE DE SEXTA',
          corte: '2023-05-15T17:00:00.000+00:00',
          sla: 0,
          uuid: 2,
          hour: '17:00:00',
          status: 2,
          slaView: 0,
        },
        {
          shippingCompany: 'STAC',
          orgVendas: 'BEL - BELÉM',
          regVendas: 'INT2X - FATURAMENTO 2X OU + NA SEMANA',
          corte: '2023-05-15T17:00:00.000+00:00',
          sla: 0,
          uuid: 3,
          hour: '17:00:00',
          status: 2,
          slaView: 0,
        },
        {
          shippingCompany: 'STAC',
          orgVendas: 'BEL - BELÉM',
          regVendas: 'INT3 - INTERIOR',
          corte: '2023-05-15T17:00:00.000+00:00',
          sla: 2,
          uuid: 4,
          hour: '17:00:00',
          status: 2,
          slaView: 2,
        },
        {
          shippingCompany: 'STAC',
          orgVendas: 'BEL - BELÉM',
          regVendas: 'LTD - LED TIME DIFERENCIADO',
          corte: '2023-05-15T18:00:00.000+00:00',
          sla: 2,
          uuid: 5,
          hour: '18:00:00',
          status: 2,
          slaView: 2,
        },
        {
          shippingCompany: 'STAC',
          orgVendas: 'BEL - BELÉM',
          regVendas: 'LTI - LED TIME INTERNO - COLBORADORES',
          corte: '2023-05-15T18:00:00.000+00:00',
          sla: 1,
          uuid: 6,
          hour: '18:00:00',
          status: 2,
          slaView: 1,
        },
        {
          shippingCompany: 'STAC',
          orgVendas: 'BEL - BELÉM',
          regVendas: 'MET - METROPOLITANA',
          corte: '2023-05-15T17:00:00.000+00:00',
          sla: 1,
          uuid: 7,
          hour: '17:00:00',
          status: 2,
          slaView: 1,
        },
      ],
      staffs: [],
      loading: false,
      superAdmin: false,
      totalPages: 0,
      size: 10,
      filters: {},
      page: 0,
      sort: 'created',
    });
  }

  reset() {
    this.shipping = [];
    this.totalPages = 0;
    this.size = 10;
    this.number = 0;
    this.sort = 'created';
    this.filters = {};
    this.loading = false;
  }

  /**Atualiza um novo registror*/
  async onChange(prop, value) {
    const _shipping = this.shipping ? this.shipping : new ShippingModel();
    switch (prop) {
      case 'sla':
        _shipping[prop] = parseInt(value);
        break;
      case 'corte':
        _shipping[prop] = `2023-04-03T${value}-03:00`;
        break;
      default:
        _shipping[prop] = value;
    }
    this.shipping = new ShippingModel(_shipping);
  }

  /**Cria um novo registro */
  async onCreate() {
    this.loading = true;
    const data = JSON.stringify(this.shipping);
    const response = await API.save(data);
    this.loading = false;
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Frete criado com sucesso.'
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async handleReset() {
    this.shippingsUpload = [];
    this.loading = true;
  }

  async handleSubmit(started) {
    const delay = 1000;
    this.loading = true;
    this.shippingsUpload.map(async (shipping) => {
      if (started === false) {
        // setTimeout(async () => {
        const data = JSON.stringify({
          corte: shipping.corte,
          hour: shipping.hour,
          orgVendas: shipping.orgVendas,
          regVendas: shipping.regVendas,
          shippingCompany: shipping.shippingCompany,
          sla: shipping.sla,
          slaView: shipping.slaView,
        });

        const response = await API.save(data);

        if (!response.error) {
          this.loading = false;
          shipping.status = 1;
          this.toastHelper.notify(
            STATUS_HELPER.SUCCESS,
            'Processo concluído !'
          );
        } else {
          this.loading = false;
          shipping.status = 3;
          this.toastHelper.notify(
            STATUS_HELPER.ERROR,
            'Faixa já existente para essa Região e ORG Vendas'
          );
        }
        // }, delay)
      }
    });
    this.loading = false;
  }

  async onCreateImport(shipping) {
    this.loading = true;
    const data = JSON.stringify({
      corte: shipping.corte,
      hour: shipping.hour,
      orgVendas: shipping.orgVendas,
      regVendas: shipping.regVendas,
      shippingCompany: shipping.shippingCompany,
      sla: shipping.sla,
      slaView: shipping.slaView,
    });

    const response = await API.save(data);

    if (!response.error) {
      this.loading = false;

      return 1;
    } else {
      this.loading = false;
      return 3;
    }
  }

  async onSave() {
    this.loading = true;
    const response = await API.save(this.shipping);
    this.loading = false;

    if (!response.error) {
      this.coupon = response.data;
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Frete adicionada com sucesso!'
      );
      this.reset();
      return true;
    }

    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return false;
  }

  async onUpdate() {
    this.loading = true;

    const data = JSON.stringify(this.shipping);
    const response = await API.update(this.shipping.uuid, data);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Informações atualizadas com sucesso!'
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 10, sort) {
    this.page = numPage;
    this.size = size;
    sort && (this.sort = sort);

    await this.getList();
    return true;
  }

  async addShipping(shippings) {
    if (isArrayEmpty(this.shippingsUpload)) {
      this.shippingsUpload = [];
    }
    this.shippingsUpload = shippings;
  }

  async removeShipping(shipping) {
    this.loading = true;
    const temp = this.shippingsUpload;
    temp.filter((i) => {
      if (i.uuid !== shipping.uuid) {
        return i;
      }
    });
    this.shippingsUpload = temp;
    this.loading = false;
  }

  /**Busca todos os registros */
  async getList(
    size = 10,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;
    const response = await API.getList({
      size,
      page,
      sort,
      ...filters,
    });
    this.loading = false;

    if (response.error) return [];
    this.shippings = response.content.map((usr) => new ShippingModel(usr));
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.loading = false;
  }

  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    const response = await API.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content.map((usr) => new ShippingModel(usr));
  }

  /**
   * Busca todas as informações de um registro.
   * @param  {string} userName
   */
  async get(userName) {
    this.loading = true;
    const response = await API.get(userName);
    this.loading = false;
    if (!response.error) {
      this.shipping = new ShippingModel(response);
      return this.shipping;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }

  async deleteShipping(Uuid) {
    const response = await API.delete(Uuid);

    if (!response.error) {
      await this.getList();
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Frete excluído com sucesso!'
      );
      return true;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
  }
}
export default ShippingStore;
