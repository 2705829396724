import React from 'react';
import { convertPrice, formatPercentage } from '../helpers/utils/Functions';
import BaseModel from './BaseModel';


export default class BestSellingProductsModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.idProduct = data.idProduct;
    this.name = data.name;
    this.product_image = <img src={data.image} width={50} />
    this.skuCode = data.skuCode;
    this.subcategoryName = data.subcategoryName;
    this.fakePrice = data.fakePrice;
    this.price = convertPrice(data.price);
    this.totalAmount = data.totalAmount;
    this.percentage = formatPercentage(data.percentage);
    this.image = data.image;
    this.categories = data.categories || 'Sem categoria';
  }
}


