const TemplateEmailPermissionEnum = Object.freeze({
  POST: {
    pattern: '/manager/template-email',
    action: 'POST',
  },
  PUT: {
    pattern: '/manager/template-email/**',
    action: 'PUT',
  },
  DELETE: {
    pattern: '/manager/template-email/**',
    action: 'DELETE',
  },
  GET: {
    pattern: '/manager/template-email/search',
    action: 'GET',
  },
  PUT_TEST: {
    pattern: '/manager/template-email/test',
    action: 'PUT',
  },
  GET_UUID: {
    pattern: '/manager/template-email/**',
    action: 'GET',
  },
});

export default TemplateEmailPermissionEnum;
