import { extendObservable } from 'mobx';

import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';

import DynamicHomeAPI from '../services/DynamicHomeAPI';

import DynamicHomeModel from '~/models/DynamicHomeModel';
import { ComponentHomeTypesEnum } from '~/helpers/utils/enums/ComponentHomeTypesEnum';
class DynamicHomeStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();

    extendObservable(this, {
      dynamicHomeList: [],
      dynamicHome: new DynamicHomeModel(),
      totalPages: 0,
      size: 10,
      page: 0,
      sort: 'created',
      filters: {},
      loading: false,
    });
  }

  reset() {
    this.dynamicHomeList = [];
    this.dynamicHome = new DynamicHomeModel();
    this.totalPages = 0;
    this.size = 10;
    this.page = 0;
    this.sort = 'created';
    this.loading = false;
  }

  initializeDynamicHome(branch_uuid) {
    this.dynamicHome = new DynamicHomeModel({
      branch: { uuid: branch_uuid },
    });
  }

  async get(uuid) {
    this.loading = true;
    const response = await DynamicHomeAPI.get(uuid);
    this.loading = false;
    if (!response.error) {
      this.dynamicHome = new DynamicHomeModel(response);
    }
  }

  async getList(
    channelUuid,
    size = 999,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;

    const response = await DynamicHomeAPI.getListChannel(channelUuid, {
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.dynamicHomeList = [];
      this.loading = false;
      return;
    }

    this.dynamicHomeList = response.content.map(
      (banner) => new DynamicHomeModel(banner)
    );
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.size = response.size;
    this.loading = false;
  }

  async getExpoxtList(
    nextUuid,
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    const response = await DynamicHomeAPI.getListChannel(nextUuid, {
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content.map((usr) => new DynamicHomeModel(usr));
  }

  async setPage(page) {
    await this.getList(this.size, page);
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, sort);
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }

  async onSave() {
    this.loading = true;
    const dynamicHome = this.dynamicHome;
    dynamicHome.channel = { uuid: dynamicHome.channel.value };
    const response = await DynamicHomeAPI.save(dynamicHome);
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Salvo com sucesso!');
    }

    return response;
  }

  async onUpdate() {
    this.loading = true;
    const dynamicHome = this.dynamicHome;
    if (dynamicHome.channel?.uuid === undefined) {
      dynamicHome.channel = { uuid: dynamicHome.channel.value };
    }

    const response = await DynamicHomeAPI.update(dynamicHome.uuid, dynamicHome);
    this.loading = false;

    if (!response.error) {
      this.reset();

      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Atualizado com sucesso!');

      return true;
    }

    this.toastHelper.notify(STATUS_HELPER.ERROR, 'Erro ao atualizar');
    return false;
  }

  async delete(uuid, component) {
    this.loading = true;
    const response = await DynamicHomeAPI.delete(uuid);
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        `Lista ${
          component?.mobile ? 'Responsivo excluído' : 'WEB excluída'
        } com sucesso!`
      );
    }

    return response;
  }

  onChange(prop, value) {
    switch (prop) {
      case 'branch_uuid':
        this.dynamicHome.branch = { uuid: value };
        break;
      case 'mobile':
        this.dynamicHome[prop] = value?.value;
        break;
      case 'type': {
        const temp = this.dynamicHome;
        temp[prop] = value?.value;

        if (value?.value === ComponentHomeTypesEnum.CATEGORY_CAROUSEL) {
          temp.banners = undefined;
        } else if (value?.value === ComponentHomeTypesEnum.BANNER_CAROUSEL) {
          temp.category = undefined;
        } else if (value?.value === ComponentHomeTypesEnum.PRODUCT_CAROUSEL) {
          temp.banners = undefined;
        }

        this.dynamicHome = new DynamicHomeModel(temp);
        break;
      }
      case 'banners':
        if (value?.value != undefined) {
          this.dynamicHome[prop] = [
            {
              uuid: value?.value,
            },
          ];
        }
        break;
      case 'category_carousel':
        if (value?.value != undefined) {
          this.dynamicHome.banners = [
            {
              uuid: value?.value,
            },
          ];
        }
        break;
      case 'category':
        if (value?.value != undefined) {
          this.dynamicHome[prop] = {
            uuid: value?.value,
          };
        }
        break;
      default:
        this.dynamicHome[prop] = value;
    }
  }
}

export default DynamicHomeStore;
