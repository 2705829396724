import { extendObservable } from 'mobx';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import ReportLogAPI from '../services/ReportLogAPI';

const initValues = {
  loading: false,
  logs: [],
  filters: {},
  sort: 'created',
  page: 0,
  defaultTo: '',
  defaultFrom: '',
  size: 20,
  page: 0,
  totalPages: 0,
};

class LogsStore {
  sort = 'created';
  filters = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
  }

  async getFirstFilterDates() {
    const fDate = new Date();
    const tDate = new Date();

    fDate.setDate(fDate.getDate() - 30);
    const fromYear = fDate.getFullYear();
    const fromMonth =
      fDate.getMonth() + 1 >= 10
        ? fDate.getMonth() + 1
        : `0${fDate.getMonth() + 1}`;
    const fromDay =
      fDate.getDate() >= 10 ? fDate.getDate() : `0${fDate.getDate()}`;
    const fromDate = `${fromYear}-${fromMonth}-${fromDay}`;

    const toYear = tDate.getFullYear();
    const toMonth =
      tDate.getMonth() + 1 >= 10
        ? tDate.getMonth() + 1
        : `0${tDate.getMonth() + 1}`;
    const toDay =
      tDate.getDate() >= 10 ? tDate.getDate() : `0${tDate.getDate()}`;
    const toDate = `${toYear}-${toMonth}-${toDay}`;

    this.defaultTo = toDate;
    this.defaultFrom = fromDate;
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getLogins(
      filters.from,
      filters.to,
      filters.user,
      this.sort,
      this.size,
      this.page
    );
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getLogins();
  }

  async setPage(numPage, size = 20, isPending = false, sort) {
    this.page = numPage;
    this.size = size;
    await this.getLogins(this.filters);
    return true;
  }

  /**Consulta o relatórios de logs de acesso*/
  async getLogins(filters) {
    this.loading = true;
    const params = {
      from: this.filters.from ? this.filters.from : this.defaultFrom,
      to: this.filters.to ? this.filters?.to : this.defaultTo,
      user: this.filters.user,
      sort: this.sort,
    };

    const size = this.size;
    const page = this.page;

    const response = await ReportLogAPI.getLogins(params, size, page);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    else {
      this.logs = response.content;
      this.totalPages = response.totalPages;
      this.page = response.number;
    }
    this.loading = false;
  }

  async getExpoxt() {
    const params = {
      from: this.filters.from ? this.filters.from : this.defaultFrom,
      to: this.filters.to ? this.filters?.to : this.defaultTo,
      user: this.filters.user,
      sort: this.sort,
    };

    const size = 9999999;
    const page = this.page;

    const response = await ReportLogAPI.getLogins(params, size, page);
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content;
  }
}

export default LogsStore;
