import BaseAPI, { URLS } from './BaseAPI';

class VoltagemAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(URLS.VOLTAGE, params);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar a lista de voltagens' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar a lista de voltagens' };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.VOLTAGE, data);
      return response.status === 201
        ? response.data
        : { error: 'Falha ao salvar voltagem' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar voltagem',
      };
    }
  }

  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.VOLTAGE}/${uuid}`, data);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao atualizar voltagem' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar voltagem',
      };
    }
  }

  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.VOLTAGE}/${uuid}`);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar voltagem' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar voltagem' };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.VOLTAGE}/${uuid}`);
      return response.status === 204
        ? response.data
        : { error: 'Falha ao excluir voltagem' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao excluir voltagem' };
    }
  }
}

export default VoltagemAPI;
