import TopBarContentsModel from './TopBarContentsModel';
import FileModel from '~/models/FileModel';

export default class StoreSetupModel {
  constructor(data = {}) {

    if (!data) return;
    this.facebook = data.facebook !== undefined ? data.facebook : "";
    this.twitter = data.twitter !== undefined ? data.twitter : "";
    this.activeMobile =
      data.activeMobile !== undefined ? data.activeMobile : "";
    this.activeWeb = data.activeWeb !== undefined ? data.activeWeb : "";
    this.companyName = data.companyName;
    this.document = data.document;
    this.address = data.address;
    this.email = data.email;
    this.phone = data.phone;
    this.openingHours = data.openingHours;
    this.zendeskKey = data.zendeskKey;
    this.homeMetaDescription = data.homeMetaDescription;
    this.homeMetaTitle = data.homeMetaTitle;
    this.primaryColor = data.primaryColor;
    this.secondaryColor = data.secondaryColor;
    this.instagram = data.instagram !== undefined ? data.instagram : "";
    this.linkedin = data.linkedin !== undefined ? data.linkedin : "";
    this.youtube = data.youtube !== undefined ? data.youtube : "";
    this.paymentTypes =
      data.paymentTypes !== undefined ? data.paymentTypes : [];
    this.colorHeaderBackground =
      data.colorHeaderBackground !== undefined
        ? data.colorHeaderBackground
        : "";
    this.colorHeaderFont =
      data.colorHeaderFont !== undefined ? data.colorHeaderFont : "";
    this.colorFooterBackground =
      data.colorFooterBackground !== undefined
        ? data.colorFooterBackground
        : "";
    this.colorFooterFont =
      data.colorFooterFont !== undefined ? data.colorFooterFont : "";
    this.colorSubHeaderBackground =
      data.colorSubHeaderBackground !== undefined
        ? data.colorSubHeaderBackground
        : "";
    this.colorSubHeaderFont =
      data.colorSubHeaderFont !== undefined ? data.colorSubHeaderFont : "";
    this.topBarContents = data.topBarContents
      ? data.topBarContents.map((prop) => new TopBarContentsModel(prop))
      : [];
    this.logo = new FileModel(data.logo) || {};
    this.favicon = new FileModel(data.favicon) || {};

  }
}
