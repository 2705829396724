import { action, decorate, extendObservable } from 'mobx';

import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';

import BannersAPI from '../services/BannersAPI';
import UploadAPI from '../services/UploadAPI';

import BranchModel from '../models/BranchModel';
import BannerModel from '~/models/BannerModel';

class BannersStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();

    extendObservable(this, {
      bannersList: [],
      bannerListSelect: undefined,
      banner: new BannerModel(),
      bannerFile: {},
      bannerEdit: {},
      branchUuid: undefined,
      totalPages: 0,
      size: 10,
      page: 0,
      sort: 'created,desc',
      filters: {},
      loading: false,
    });
  }

  reset() {
    this.bannersList = [];
    this.bannerListSelect = undefined;
    this.banner = new BannerModel();
    this.bannerFile = {};
    this.bannerEdit = {};
    this.totalPages = 0;
    this.size = 10;
    this.page = 0;
    this.sort = 'created,desc';
    this.loading = false;
  }

  initializeBanner() {
    this.banner = new BannerModel();
    this.bannerFile = {};
  }

  async getList(
    size = 10,
    page = this.page,
    sort = this.sort != 'nameTag' ? this.sort : 'name_tag',
    filters = this.filters
  ) {
    this.loading = true;
    const response = await BannersAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });
    this.loading = false;

    if (response.error) {
      this.bannersList = [];
      return;
    }

    this.bannersList = response.content.map(
      (banner) => new BannerModel(banner)
    );
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.size = response.size;
  }

  async setPage(page) {
    await this.getList(this.size, page);
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, this.sort);
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }

  async saveBranch(uuid) {
    this.branchUuid = uuid;
  }

  async getBannerListSelect() {
    const response = await BannersAPI.getList({
      size: 300,
      page: 0,
    });

    if (response.error) {
      this.bannerListSelect = [];
      return;
    }

    this.bannerListSelect = response.content
      .filter((banner) => banner.active === true)
      .map((banner) => ({
        value: banner.uuid,
        label: banner.nameTag,
      }));
  }

  async uploadFiles(files) {
    const promisses = files.map(async (item) => {
      const response = await UploadAPI.saveFile([item.file], 'banner');

      if (response.error) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      } else {
        return {
          file: {
            uuid: response.data.uuid,
          },
          name: item.name,
          ordering: item.ordering,
          subDescription: item.subDescription,
          description: item.description,
          link: item.link,
        };
      }
    });

    const responses = await Promise.all(promisses);

    if (responses.filter((el) => el.error).length > 0) {
      await this.roollbackFiles(responses.filter((el) => !el.error));
      throw new Error(
        responses
          .filter((el) => el.error)
          .map((el) => el.error)
          .join('; ')
      );
    }

    return responses;
  }

  async roollbackFiles(files) {
    try {
      const promisses = files.map((file) =>
        UploadAPI.removeFile(file.file.uuid)
      );
      await Promise.all(promisses);
    } catch (error) {
      console.log('roollbackFiles error', error);
    }
  }

  async save() {
    this.loading = true;

    const merchant = this.rootStore.usersStore.userMerchant;
    const filesResponses = await this.uploadFiles(this.banner.files);
    const data = JSON.stringify({
      ...this.banner,
      files: filesResponses.filter((file) => !file.error),
      merchant,
    });

    const response = await BannersAPI.save(data);
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Salvo com sucesso!');
    }

    return response;
  }

  async update() {
    this.loading = true;

    const merchant = this.rootStore.usersStore.userMerchant;

    const newFiles = this.banner.files.filter(
      (item) => item.file.uuid === undefined
    );
    const files = this.banner.files.filter(
      (item) => item.file.uuid !== undefined
    );
    const filesResponses = await this.uploadFiles(newFiles);
    if (filesResponses.length > 0) {
      files.push(...filesResponses.filter((file) => !file.error));
    }

    const data = JSON.stringify({
      ...this.banner,
      files: files,
      merchant,
    });

    const response = await BannersAPI.update(this.banner.uuid, data);
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      newFiles.map(async (item) => {
        await this.removeFile(item);
      });
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Atualizado com sucesso!');
    }

    return response;
  }

  async get(uuid) {
    this.loading = true;
    const response = await BannersAPI.get(uuid);
    this.banner = new BannerModel(response);
    this.loading = false;
  }

  async getByFileUuid(uuid) {
    this.bannerEdit = this.banner.files.find((banner) => banner.uuid === uuid);
  }

  async updateBannerEdit(name, value) {
    if (value != undefined) {
      if (name === 'file') {
        this.bannerEdit.file = value[0];
      } else {
        this.bannerEdit[name] = value;
      }
    }
  }

  async updateBannerFile(file) {
    const files = this.banner.files;

    const indexToRemove = files.findIndex((item) => item.uuid === file.uuid);

    files.splice(indexToRemove, 1);
    files.push(file);

    this.banner.files = files;
    this.bannerEdit = {};

    this.banner = new BannerModel(this.banner);
  }

  async delete(uuid) {
    this.loading = true;
    const response = await BannersAPI.delete(uuid);
    this.loading = false;
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Excluído com sucesso!');
    }

    return response;
  }

  async addFile() {
    const tempBanner = this.banner;
    tempBanner.files.push({
      uuid: Math.random() * 21,
      file: this.bannerFile.banner_file_file[0],
      description: this.bannerFile.banner_file_description,
      link: this.bannerFile.banner_file_link,
      name: this.bannerFile.banner_file_name,
      ordering: this.bannerFile.banner_file_ordering,
      subDescription: this.bannerFile.banner_file_subDescription,
    });

    this.banner = new BannerModel(tempBanner);
    this.bannerFile = {};
    // this.uploadFiles();
  }

  async removeFile(file) {
    if (this.banner.uuid && file.uuid) {
      await BannersAPI.removeImage(this.banner.uuid, file.uuid);
      await this.get(this.banner.uuid);
    } else {
      const tempBanner = this.banner;
      tempBanner.files = tempBanner.files.filter((item) => item !== file);
      this.banner = new BannerModel(tempBanner);
    }
  }

  onChangeBannerFile(prop, value) {
    switch (prop) {
      default: {
        this.bannerFile[prop] = value;
      }
    }
  }

  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    const response = await BannersAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    this.loading = false;
    return response.content.map((usr) => new BannerModel(usr));
  }

  onChange(prop, value) {
    const tempBanner = this.banner;
    switch (prop) {
      case 'banner_file_file':
      case 'banner_file_name':
      case 'banner_file_ordering':
      case 'banner_file_link':
      case 'banner_file_subDescription':
      case 'banner_file_description':
        this.onChangeBannerFile(prop, value);
        return;
      case 'branch':
        tempBanner.branch = new BranchModel({ uuid: value.value });
        break;
      case 'tag':
        tempBanner.tag = value?.value;
        break;
      default:
        tempBanner[prop] = value;
    }

    this.banner = new BannerModel(tempBanner);
  }
}

export default BannersStore;
