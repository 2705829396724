import BaseAPI, { URLS } from './BaseAPI';

class BranchesAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(URLS.BRANCHES, params);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar a lista de filiais' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar a lista de filiais' };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.BRANCHES, data);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao salvar filial' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar filial',
      };
    }
  }

  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.BRANCHES}/${uuid}`, data);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao atualizar filial' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar filial',
      };
    }
  }

  static async get(uuid, params) {
    try {
      const response = await BaseAPI.get(`${URLS.BRANCHES}/${uuid}`, params);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar filial' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar filial' };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.BRANCHES}/${uuid}`);
      return response.status === 204
        ? response.data
        : { error: 'Falha ao excluir filial' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao excluir filial' };
    }
  }
}

export default BranchesAPI;
