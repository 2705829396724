import BaseAPI, { URLS } from './BaseAPI';

class StoreSetupAPI {
  static async get() {
    try {
      const response = await BaseAPI.get(URLS.STORE_SETUP);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar' };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.STORE_SETUP, data);
      return response.status === 201
        ? response.data
        : { error: 'Falha ao salvar' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar',
      };
    }
  }

  static async update(data) {
    try {
      const response = await BaseAPI.put(`${URLS.STORE_SETUP}`, data);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao atualizar' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar Configuração da Loja',
      };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.STORE_SETUP}/${uuid}`);
      return response.status === 204
        ? response.data
        : { error: 'Falha ao excluir' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao excluir' };
    }
  }


  static async getConfigNew() {
    try {
      const response = await BaseAPI.delete(`${URLS.TRESCORACOESCONFIG}`);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar' };
    }
  }
}

export default StoreSetupAPI;
