import { extendObservable } from 'mobx';
import BaseModel from './BaseModel';
import DiscountModel from './DiscountModel';
import VoucherModel from './VoucherModel';
class CouponModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.name = data.name;
    this.maxUses = parseInt(data.maxUses) || 0;
    this.discount = new DiscountModel(data.discount);
    this.vouchers =
      data.vouchers?.length > 0
        ? data.vouchers.map((props) => new VoucherModel(props))
        : [];
    this.restrictions = data.restrictions || [];
    this.active = data.active;

    extendObservable(this, {
      active: data.active ?? false,
    });
  }
}

export default CouponModel;
