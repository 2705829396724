import TopBarContentsModel from './TopBarContentsModel';
import FileModel from '~/models/FileModel';

export default class ConfigStoreModel {
  constructor(data = {}) {

    if (!data) return;
    this.key = data.key;
    this.value = data.value;
    this.description = data.description;
    this.uuid = data.uuid;
  }
}
