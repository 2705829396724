import BaseModel from './BaseModel';

class ShippingModel extends BaseModel {
  
  constructor(data = {}) {
    if (!data) return;
    super(data);
    this.shippingCompany = data.shippingCompany;
    this.orgVendas = data.orgVendas;
    this.regVendas = data.regVendas;
    this.sla = data.sla;
    this.corte = data.corte;
  }
}

export default ShippingModel;
