import axios from 'axios';
import BaseAPI, { URLS, baseURL } from './BaseAPI';
import StorageUtil, { KEYS } from '~/helpers/utils/StorageUtil';

class UploadAPI {
  /**Atualiza imagem */
  static async updateFile(file) {
    try {
      const url = `manager${URLS.FILES}/${file.uuid}`;
      const response = await this._updateFiles(url, file);
      if (response.status === 200) return response;
      else return { error: 'Não foi possível enviar o arquivo!' };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /**Atualiza imagem */
  static async getProductsCSV(file) {
    try {
      const url = `${URLS.MANAGER}/products/export-csv`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response;
      else return { error: 'Não foi possível enviar o arquivo!' };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /**Checa Csv Antes de enviar*/
  static async checkCSV(file) {
    try {
      const url = `${URLS.MANAGER}/products/check-csv`;
      const response = await this._updateCSV(url, file);
      if (response.status === 200) return response;
      else return { error: 'Não foi possível enviar o arquivo!' };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /**Atualiza CSV */
  static async sendCSV(file) {
    try {
      const url = `${URLS.MANAGER}/products/update-csv`;
      const response = await this._updateCSVPut(url, file);
      if (response.status === 200) return response;
      else return { error: 'Não foi possível enviar o arquivo!' };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /**Upload de arquivos */
  static async _updateCSV(endpoint, uploadItem) {
    const form = new FormData();
    form.append('file', uploadItem);
    const data = form;
    const config = getConfig(uploadItem);
    return await axios.post(`${baseURL()}${endpoint}`, data, config);
  }

  /**Upload de arquivos */
  static async _updateCSVPut(endpoint, uploadItem) {
    const form = new FormData();
    form.append('file', uploadItem);
    const data = form;
    const config = getConfig(uploadItem);
    return await axios.put(`${baseURL()}${endpoint}`, data, config);
  }

  /*
   *Upload de iamgem de filial
   */
  static async uploadBranch(uuid, uploadItem) {
    try {
      const url = `manager/branches/file/${uuid}`;
      const response = await this._uploadFiles(url, uploadItem);
      if (response.status === 200) return response;
      return { error: 'Não foi possível enviar o arquivo!' };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /*
   *Upload de imagem de loja
   */
  static async uploadOurStore(uuid, uploadItem) {
    try {
      const url = `manager/stores/file/${uuid}`;
      const response = await this._uploadFiles(url, uploadItem);
      if (response.status === 200) return response;
      return { error: 'Não foi possível enviar o arquivo!' };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /*
   *Upload de imagem de produtos por URL
   */
  static async uploadProductByImageURL(productUuid, uploadItem) {
    try {
      const url = `manager/files/products/path/${productUuid}`;
      const response = await this._uploadFiles(url, uploadItem);
      if (response.status === 200) return response;
      return { error: 'Não foi possível enviar o arquivo!' };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /*
   *Upload de imagem de produto por arquivo
   */
  static async uploadProductByImageFile(productUuid, uploadItem) {
    try {
      const url = `manager/files/products/${productUuid}`;
      const response = await this._uploadFiles(url, uploadItem);
      if (response.status === 200) return response;
      return { error: 'Não foi possível enviar o arquivo!' };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /*
   *Upload de produtos
   */
  static async uploadProduct(productUuid, uploadItem) {
    try {
      const url = `manager/files/products/${productUuid}`;
      const response = await this._uploadFiles(url, uploadItem);
      if (response.status === 200) return response;
      return { error: 'Não foi possível enviar o arquivo!' };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /*
   *Upload de produtos
   */
  static async uploadLogoSupplier(supplierUuid, uploadItem) {
    try {
      const url = `${URLS.SUPPLIER}/${supplierUuid}${URLS.FILES}`;
      const response = await this._uploadFiles(url, uploadItem);
      if (response.status === 200) return response;
      else return { error: 'Não foi possível enviar o arquivo!' };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /*
   * Upload de imagem da categoria
   */
  static async uploadCategory(categoryUuid, uploadItem) {
    try {
      const url = `manager/files/categories/${categoryUuid}`;
      const response = await this._uploadFiles(url, uploadItem);
      if (response.status === 200) return response;
      else return { error: 'Não foi possível enviar o arquivo!' };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /*
    * Upload de imagem de propriedade
    properties/values/{uuid}/file
    */
  static async uploadProperty(propertyUuid, uploadItem) {
    try {
      const url = `${URLS.PROPERTY}/values/${propertyUuid}/file`;
      const response = await this._uploadFiles(url, uploadItem);
      if (response.status === 200) return response;
      else return { error: 'Não foi possível enviar o arquivo!' };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /*
   * Upload de proposta de pedido
   */
  static async uploadOrder(orderUuid, uploadItem) {
    try {
      const url = `${URLS.MANAGER}${URLS.ORDERS}/${orderUuid}${URLS.FILES}`;
      const response = await this._uploadFiles(url, uploadItem);
      if (response.status === 200) return response;
      else return { error: 'Não foi possível enviar o arquivo!' };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /**Remove arquivo*/
  static async removeFile(uuid) {
    const url = `${baseURL()}/files/${uuid}`;
    const config = {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${StorageUtil.getItem(KEYS.TOKEN_KEY)}`,
        'Content-Type': 'multipart/form-data',
      },
    };
    return await axios.delete(url, config);
  }

  /**Remove arquivo*/
  static async removeFileCategory(uuid) {
    const url = `${baseURL()}manager/files/${uuid}`;
    const config = {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${StorageUtil.getItem(KEYS.TOKEN_KEY)}`,
        'Content-Type': 'multipart/form-data',
      },
    };
    return await axios.delete(url, config);
  }

  static async saveFile(file, metaTags = '') {
    try {
      const url = `${URLS.MANAGER}/files?metaTags=${metaTags}`;
      const form = new FormData();
      form.append('file', file[0]);
      const config = getConfigBanner(file[0]);
      const response = await BaseAPI.post(`${url}`, form, config);

      if (response.status === 200) return response;
      return { error: 'Não foi possível enviar o arquivo!' };
    } catch (e) {
      return { error: e.message };
    }
  }

  static async updateFile(file, metaTags = '') {
    try {
      const url = `${URLS.MANAGER}/files?metaTags=${metaTags}`;
      const form = new FormData();
      form.append('file', file[0]);
      const config = getConfigBanner(file[0]);

      const response = await BaseAPI.post(`${url}`, form, config);

      if (response.status === 200) return response;
      return { error: 'Não foi possível enviar o arquivo!' };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }
  /**Upload de arquivos */
  static async _updateFiles(endpoint, uploadItem) {
    const data = uploadItem.getFormData();
    const config = getConfig(uploadItem);
    return await axios.put(`${baseURL()}${endpoint}`, data, config);
  }

  /**Upload de arquivos */
  static async _uploadFiles(endpoint, uploadItem) {
    const data = uploadItem.getFormData();
    const config = getConfig(uploadItem);
    return await axios.post(`${baseURL()}${endpoint}`, data, config);
  }

  /**
   * Request que faz upload de imagem do banner
   * @param  {Object} file - Objeto com a imagem
   * @param  {String} bannerUuid - Id do banner
   */
  static async sendNewFileBanner(bannerUuid, bannerFile, dataFile) {
    try {
      const url = `${URLS.MANAGER}/files?metaTags=banners`;
      const form = new FormData();
      form.append('file', bannerFile[0]);
      const config = getConfigBanner(bannerFile[0]);

      const response = await BaseAPI.post(`${url}`, form, config);

      if (response.status === 200) return response;
      return { error: 'Não foi possível enviar o arquivo!' };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /**Upload de arquivos para o banner */
  static async _uploadFilesBanner(endpoint, bannerFile, dataFile) {
    const form = new FormData();
    form.append('file', dataFile[0]);
    form.append(
      'bannerFile',
      new Blob([JSON.stringify(bannerFile)], { type: 'application/json' }),
      'bannerFile'
    );

    const config = getConfigBanner(dataFile[0]);
    return await axios.post(`${baseURL()}${endpoint}`, form, config);
  }

  /**Remove arquivo*/
  static async removeFileProduct(uuid) {
    try {
      const url = `manager/files/${uuid}`;
      const config = {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${StorageUtil.getItem(KEYS.TOKEN_KEY)}`,
          'Content-Type': 'multipart/form-data',
        },
      };
      const response = await BaseAPI.delete(url, config);
      if (response.status === 200) return response.data;
      return { error: 'Não foi possível excluir imagem' };
    } catch (e) {
      return this._exception(e, 'Não foi possível excluir a imagem');
    }
  }

  static async uploadImageHeaderStoreSetup(file) {
    try {
      const url = `${URLS.MANAGER}/config/logo`;
      const form = new FormData();
      form.append('file', file);
      const response = await BaseAPI.post(`${url}`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200) return response;
      return { error: 'Não foi possível enviar o arquivo!' };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  static async removeImageHeaderStoreSetup(uuid) {
    try {
      const url = `config/logo/${uuid}`;
      const config = {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${StorageUtil.getItem(KEYS.TOKEN_KEY)}`,
          'Content-Type': 'multipart/form-data',
        },
      };
      const response = await BaseAPI.delete(url, config);
      if (response.status === 200) return response.data;
      return { error: 'Não foi possível excluir imagem' };
    } catch (e) {
      return this._exception(e, 'Não foi possível excluir a imagem');
    }
  }

  static async uploadFavIconHeaderStoreSetup(file) {
    try {
      const url = `${URLS.MANAGER}/config/favicon`;
      const form = new FormData();
      form.append('file', file);
      const response = await BaseAPI.post(`${url}`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200) return response;
      return { error: 'Não foi possível enviar o arquivo!' };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  static async removeFavIconHeaderStoreSetup(uuid) {
    try {
      const url = `config/favicon/${uuid}`;
      const config = {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${StorageUtil.getItem(KEYS.TOKEN_KEY)}`,
          'Content-Type': 'multipart/form-data',
        },
      };
      const response = await BaseAPI.delete(url, config);
      if (response.status === 200) return response.data;
      return { error: 'Não foi possível excluir imagem' };
    } catch (e) {
      return this._exception(e, 'Não foi possível excluir a imagem');
    }
  }
}

const getConfig = (uploadItem) => ({
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${StorageUtil.getItem(KEYS.TOKEN_KEY)}`,
    'Content-Type': 'multipart/form-data',
  },
  onUploadProgress: (progressEvent) =>
    (uploadItem.percentCompleted = parseInt(
      Math.floor((progressEvent.loaded * 100) / progressEvent.total)
    )),
});

const getConfigBanner = (uploadItem) => ({
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${StorageUtil.getItem(KEYS.TOKEN_KEY)}`,
    'Content-Type': 'application/json',
  },
  onUploadProgress: (progressEvent) =>
    (uploadItem.percentCompleted = parseInt(
      Math.floor((progressEvent.loaded * 100) / progressEvent.total)
    )),
});

export default UploadAPI;
