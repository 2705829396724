import { extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import SalesAPI from "~/services/SalesAPI";

const initValues = {
  loading: false,
  topViewProducts: [],
  filters: {},
  defaultTo: '',
  defaultFrom: '',
  size: 15,
  page: 0,
  totalPages: 0,
};

class TopViewProductsStore {
  totalPages = 0;
  page = 0;
  size = 15;
  sort = "modified,desc";
  filter = "";

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
  }

  async setFilters(filters) {
    this.filters = filters;
    this.page = 0;
    await this.getTopViewProducts(this.defaultFrom);
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getTopViewProducts();
  }

  async setPage(numPage, size = 15) {
    this.page = numPage;
    this.size = size;
    await this.getTopViewProducts();
    return true;
  }

  async getFirstFilterDates() {
    const fDate = new Date();
    const tDate = new Date();

    fDate.setDate(fDate.getDate() - 30);
    const fromYear = fDate.getFullYear();
    const fromMonth =
      fDate.getMonth() + 1 >= 10
        ? fDate.getMonth() + 1
        : `0${fDate.getMonth() + 1}`;
    const fromDay =
      fDate.getDate() >= 10 ? fDate.getDate() : `0${fDate.getDate()}`;
    const fromDate = `${fromYear}-${fromMonth}-${fromDay}`;

    const toYear = tDate.getFullYear();
    const toMonth =
      tDate.getMonth() + 1 >= 10
        ? tDate.getMonth() + 1
        : `0${tDate.getMonth() + 1}`;
    const toDay =
      tDate.getDate() >= 10 ? tDate.getDate() : `0${tDate.getDate()}`;
    const toDate = `${toYear}-${toMonth}-${toDay}`;

    this.defaultTo =  toDate;
    this.defaultFrom = fromDate;
  };


  async setFiltersGetTopViewProducts(filters) {
    this.filters = filters;
    await this.getTopViewProducts(this.filters);
  }

  /**Consulta o Relatório de produtos mais comprados*/
  async getTopViewProducts() {
    this.loading = true;
    const params = {
      "@class": "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
      from: this.filters?.from ? this.filters?.from : this.defaultFrom,
      to: this.filters?.to ? this.filters?.to : this.defaultTo,
      categoryName: this.filters?.categoryName?.label,
      productName: this.filters?.productName,
      sort: this.sort,
    };

    const size = this.size;
    const page = this.page;

    const response = await SalesAPI.getTopViewProducts(params, size, page);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    else {
      this.topViewProducts = response;
      this.totalPages = response.totalPages;
      this.page = response.number;
    }
    this.loading = false;
  }

  async getExpoxtList() {
    const params = {
      "@class": "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
      from: this.filters?.from ? this.filters?.from : this.defaultFrom,
      to: this.filters?.to ? this.filters?.to : this.defaultTo,
      categoryName: this.filters?.categoryName?.label,
      productName: this.filters?.productName,
      sort: this.sort,
    };

    const size = 99999;

    const response = await SalesAPI.getTopViewProducts(params, size, 0);
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
   return response.content
  }
}

export default TopViewProductsStore;
