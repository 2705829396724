import BaseModel from './BaseModel';
import FileModel from './FileModel';

export default class UnitMeasurementModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this['@class'] =
      'br.com.stoom.tresCoracoes.model.TresCoracoesUnitOfMeasurement';
    this.uuid = data?.uuid;
    this.name = data?.name;
    this.externalId = data?.externalId;
    this.valStart = parseInt(data.valStart);
    this.valEnd = parseInt(data.valEnd);
    this.baseQty = parseInt(data.baseQty);
    this.displayUnits = data.displayUnits;
  }
}
class Unit extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.label = data.label;
    this.value = data.value;
  }
}
