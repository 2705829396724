import BaseModel from './BaseModel';

const handleCompletZipCode = (value) => {

  if (value.length === 1) {
    return `0000000${value}`
  } else if (value.length === 2) {
    return `000000${value}`
  } else if (value.length === 3) {
    return `00000${value}`
  } else if (value.length === 4) {
    return `0000${value}`
  } else if (value.length === 5) {
    return `000${value}`
  } else if (value.length === 6) {
    return `00${value}`
  } else if (value.length === 7) {
    return `0${value}`
  } else if (value.length === 8) {
    return value
  } 
}

class AddressModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.street = data.street;
    this.number = data.number;
    this.block = data.block;
    this.zipCode =  handleCompletZipCode(String(data.zipCode))
    this.city = data.city;
    this.state = data.state;
    this.country = data.country || 'BRASIL';
    this.refAddress = data.refAddress;
    this.addressComplement = data.addressComplement;
  }

  get isValid() {
    if (!this.zipCode) return false;
    if (!this.street) return false;
    if (!this.city) return false;
    if (!this.block) return false;
    if (!this.number) return false;
    if (!this.state) return false;
    return true;
  }
}

export default AddressModel;
