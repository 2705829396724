const EvaluationsPermissionEnum = Object.freeze({
  GET: {
    pattern: '/manager/products/evaluations/**',
    action: 'GET',
  },
  PUT: {
    pattern: '/manager/products/evaluations/**',
    action: 'PATCH',
  },
});

export default EvaluationsPermissionEnum;
