import BaseModel from './BaseModel';
import MerchantModel from './MerchantModel';
export default class BannerModel {
  constructor(data = {}) {
    if (!data) return;
    this.merchant = data.merchant && new MerchantModel(data.merchant);
    this.uuid = data.uuid;
    this.nameTag = data.nameTag;
    this.tag = data.tag;
    this.responsive = data.responsive;
    this.active = data.active;
    this.files = data.files || [];
  }
}
