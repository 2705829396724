const VoltagesPermissionEnum = Object.freeze({
  POST: {
    pattern: '/manager/voltage',
    action: 'POST',
  },
  PUT: {
    pattern: '/manager/voltage/**',
    action: 'PUT',
  },
  DELETE: {
    pattern: '/manager/voltage/**',
    action: 'DELETE',
  },
  GET: {
    pattern: '/manager/voltage',
    action: 'GET',
  },
  GET_UUID: {
    pattern: '/manager/voltage/**',
    action: 'GET',
  },
});

export default VoltagesPermissionEnum;
