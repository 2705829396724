import AdministratorStore from './AdministratorStore';
import AuthStore from './AuthStore';
import MenuStore from './MenuStore';
import MerchantStore from './MerchantStore';
import UsersStore from './UsersStore';
import CustomerTypeStore from './CustomerTypeStore';
import CategoryStore from './CategoryStore';
import ProductStore from './ProductStore';
import PerfilStore from "./PerfilStore";
import BranchesStore from './BranchesStore';
import BannersStore from './BannersStore';
import OurStoresStore from './OurStoresStore';
import NotificationStore from './NotificationStore';
import DynamicHomeStore from './DynamicHomeStore';
import ColorStore from './ColorStore';
import ChannelStore from './ChannelStore';
import CouponStore from "./CouponStore";
import OrderStore from './OrderStore'
import ShippingStore from './ShippingStore';
import SubChannelStore from './SubChannelStore';
import SizeStore from './SizeStore';
import NewsLetterStore from './NewsLetterStore';
import CampaignStore from './CampaignStore';
import ProductReviewStore from './ProductReviewStore';
import CustomerStore from './CustomerStore';
import SalesStore from './SalesStore';
import TopViewProductsStore from './TopViewProductsStore';
import LogsStore from './LogsStore';
import AbandonedCartsStore from './AbandonedCartsStore';
import UnitStore from './UnitStore';
import TemplateStore from './TemplateStore';
import PropertiesStore from './PropertiesStore';
import VoltagemStore from './VoltagemStore';
import MaterialStore from './MaterialStore';
import StoreSetupStore from './StoreSetupStore';
import StampStore from './StampStore';
import InstitutionalStore from './InstitutionalStore';
import DashboardStore from './DashboardStore';
import ConfigStore from './ConfigStore';

/**
 * Rootstore guarda a instancia das stores.
 * Assim a comunicação das stores é feita atraves da rootStore.
 *
 * https://mobx.js.org/best/store.html
 */

class RootStore {
  _mobxStores = null;

  constructor(
    serializedStores = {
      usersStore: {},
      customerTypeStore: {},
      authStore: {},
      menuStore: {},
      merchantStore: {},
      administratorStore: {},
      categoryStore: {},
      productStore: {},
      branchesStore: {},
      configStore: {},
      outStoresStore: {},
      notificationStore: {},
      colorStore: {},
      channelStore : {},
      subChannelStore : {},
      sizeStore: {},
      newsLetterStore: {},
      campaignStore: {},
      productReviewStore: {},
      customerStore: {},
      salesStore: {},
      topViewProductsStore: {},
      logsStore: {},
      abandonedCartsStore: {},
      unitStore: {},
      templateStore: {},
      propertiesStore: {},
      voltagemStore: {},
      materialStore: {},
      couponStore: {},
      orderStore : {},
      shippingStore : {},
      storeSetupStore :{},
      stampStore : {},
      institutionalStore : {},
      dashboardStore :{},
      perfilStore: {},

    }
  ) {
    if (this._mobxStores === null) {
      this.usersStore = new UsersStore(this, {
        ...serializedStores.usersStore,
      });
      this.propertiesStore = new PropertiesStore(this, {
        ...serializedStores.propertiesStore,
      });
      this.customerTypeStore = new CustomerTypeStore(this, {
        ...serializedStores.customerTypeStore,
      });
      this.authStore = new AuthStore(this, {
        ...serializedStores.authStore,
      });
      this.menuStore = new MenuStore(this, {
        ...serializedStores.menuStore,
      });
      this.merchantStore = new MerchantStore(this, {
        ...serializedStores.merchantStore,
      });
      this.administratorStore = new AdministratorStore(this, {
        ...serializedStores.administratorStore,
      });
      this.categoryStore = new CategoryStore(this, {
        ...serializedStores.categoryStore,
      });
      this.productStore = new ProductStore(this, {
        ...serializedStores.productStore,
      });
      this.branchesStore = new BranchesStore(this, {
        ...serializedStores.branchesStore,
      });
      this.ourStoresStore = new OurStoresStore(this, {
        ...serializedStores.outStoresStore,
      });
      this.bannersStore = new BannersStore(this, {
        ...serializedStores.bannersStore,
      });
      this.notificationStore = new NotificationStore(this, {
        ...serializedStores.notificationStore,
      });
      this.dynamicHomeStore = new DynamicHomeStore(this, {
        ...serializedStores.dynamicHomeStore,
      });
      this.colorStore = new ColorStore(this, {
        ...serializedStores.colorStore,
      });
      this.channelStore = new ChannelStore(this, {
        ...serializedStores.channelStore,
      });
      this.orderStore = new OrderStore(this, {
        ...serializedStores.orderStore,
      });
      this.subChannelStore = new SubChannelStore(this, {
        ...serializedStores.subChannelStore,
      });
      this.sizeStore = new SizeStore(this, {
        ...serializedStores.sizeStore,
      });
      this.newsLetterStore = new NewsLetterStore(this, {
        ...serializedStores.newsLetterStore,
      });
      this.campaignStore = new CampaignStore(this, {
        ...serializedStores.campaignStore,
      });
      this.productReviewStore = new ProductReviewStore(this, {
        ...serializedStores.productReviewStore,
      });
      this.customerStore = new CustomerStore(this, {
        ...serializedStores.customerStore,
      });
      this.couponStore = new CouponStore(this, {
        ...serializedStores.couponStore,
      });
      this.salesStore = new SalesStore(this, {
        ...serializedStores.salesStore,
      });
      this.topViewProductsStore = new TopViewProductsStore(this, {
        ...serializedStores.topViewProductsStore,
      });
      this.logsStore = new LogsStore(this, {
        ...serializedStores.logsStore,
      });
      this.abandonedCartsStore = new AbandonedCartsStore(this, {
        ...serializedStores.abandonedCartsStore,
      });
      this.unitStore = new UnitStore(this, {
        ...serializedStores.unitStore,
      });
      this.configStore = new ConfigStore(this, {
        ...serializedStores.configStore,
      });
      this.templateStore = new TemplateStore(this, {
        ...serializedStores.templateStore,
      });
      this.voltagemStore = new VoltagemStore(this, {
        ...serializedStores.voltagemStore,
      });
      this.materialStore = new MaterialStore(this, {
        ...serializedStores.materialStore,
      });
      this.shippingStore = new ShippingStore(this, {
        ...serializedStores.shippingStore
      })
      this.storeSetupStore = new StoreSetupStore(this, {
        ...serializedStores.storeSetupStore
      })
      this.stampStore = new StampStore(this, {
        ...serializedStores.stampStore
      })
      this.institutionalStore = new InstitutionalStore(this, {
        ...serializedStores.institutionalStore
      })

      this.dashboardStore = new DashboardStore(this, {
        ...serializedStores.dashboardStore
      })
      this.perfilStore = new PerfilStore(this, {
        ...serializedStores.perfilStore,
      });
    }
  }

  get mobxStores() {
    return (this._mobxStores = {
      usersStore: this.usersStore,
      customerTypeStore: this.customerTypeStore,
      authStore: this.authStore,
      menuStore: this.menuStore,
      merchantStore: this.merchantStore,
      administratorStore: this.administratorStore,
      categoryStore: this.categoryStore,
      productStore: this.productStore,
      branchesStore: this.branchesStore,
      ourStoresStore: this.ourStoresStore,
      configStore: this.configStore,
      couponStore: this.couponStore,
      bannersStore: this.bannersStore,
      notificationStore: this.notificationStore,
      dynamicHomeStore: this.dynamicHomeStore,
      colorStore: this.colorStore,
      channelStore : this.channelStore,
      subChannelStore: this.subChannelStore,
      sizeStore: this.sizeStore,
      newsLetterStore: this.newsLetterStore,
      campaignStore: this.campaignStore,
      productReviewStore: this.productReviewStore,
      customerStore: this.customerStore,
      salesStore: this.salesStore,
      topViewProductsStore: this.topViewProductsStore,
      logsStore: this.logsStore,
      abandonedCartsStore: this.abandonedCartsStore,
      unitStore: this.unitStore,
      templateStore: this.templateStore,
      propertiesStore: this.propertiesStore,
      voltagemStore: this.voltagemStore,
      materialStore: this.materialStore,
      orderStore : this.orderStore,
      shippingStore : this.shippingStore,
      storeSetupStore : this.storeSetupStore,
      stampStore : this.stampStore,
      institutionalStore : this.institutionalStore,
      dashboardStore : this.dashboardStore,
      perfilStore: this.perfilStore,

    });
  }
}

let store = null;

export function initializeStores(props) {
  if (store === null) {
    store = new RootStore(props);
  }
  return store;
}

export default RootStore;
