import { action, decorate, extendObservable } from 'mobx';

import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import BranchModel from '~/models/BranchModel';

import BranchesAPI from '../services/BranchesAPI';
import FileModel from '~/models/FileModel';
import UploadAPI from '../services/UploadAPI';

class BranchesStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    // const merchant = this.rootStore.usersStore.userMerchant;

    extendObservable(this, {
      branchesList: [],
      branchesListSelect: [],
      branch: undefined,
      branchFile: undefined,
      totalPages: 0,
      size: 10,
      page: 0,
      sort: 'created,desc',
      filters: {},
      loading: false,
      pageBanner: 10,
      sizeBanner: 0,
      pageBanner: 0,
      sortBanner: 'ordering,desc',
      loadingBanner: false,
    });
  }

  reset() {
    this.branchesList = [];
    this.branchesListSelect = [];
    this.branch = undefined;
    this.totalPages = 0;
    this.size = 10;
    this.page = 0;
    this.sort = 'created,desc';
    this.loading = false;
    this.totalPagesBanner = 0;
    this.pageBanner = 10;
    this.sizeBanner = 0;
    this.pageBanner = 0;
    this.sortBanner = 'created,desc';
  }

  initializeBranch() {
    const merchant = this.rootStore.usersStore.userMerchant;
    this.branch = new BranchModel({ merchant });
  }

  async getBranchesListSelect() {
    const response = await BranchesAPI.getList({
      size: 100,
      page: 0,
      sort: 'name',
    });

    if (response.error) {
      this.branchesListSelect = [];
      return;
    }

    this.branchesListSelect = response.content.map((branch) => ({
      value: branch.uuid,
      label: branch.name,
    }));
  }

  async getList(
    size = 10,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;
    const response = await BranchesAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });
    this.loading = false;

    if (response.error) {
      this.branchesList = [];
      return;
    }

    this.branchesList = response.content.map(
      (branch) => new BranchModel(branch)
    );
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.size = response.size;
  }

  async setPage(page) {
    await this.getList(this.size, page);
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, sort);
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }

  getFileData() {
    return new FileModel({
      file: this.branchFile[0],
      metaTags: ['branch'],
      name: this.branchFile[0].name,
    });
  }

  async save() {
    this.loading = true;
    const response = await BranchesAPI.save({
      ...this.branch,
      // merchant
    });
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return response;
    }

    if (this.branchFile && this.branchFile.length) {
      await UploadAPI.uploadBranch(response.uuid, this.getFileData());
    }

    this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Salvo com sucesso!');
    return response;
  }

  async update() {
    // const merchant = this.rootStore.usersStore.userMerchant;

    this.loading = true;
    const response = await BranchesAPI.update(this.branch.uuid, {
      ...this.branch,
      // merchant
    });
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return response;
    }

    if (this.branchFile && this.branchFile.length) {
      await UploadAPI.uploadBranch(response.uuid, this.getFileData());
    }

    this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Atualizado com sucesso!');
    return response;
  }

  async getOld(uuid) {
    this.loading = true;
    const response = await BranchesAPI.get(uuid);
    this.loading = false;

    this.branch = new BranchModel({
      ...response,
    });
  }

  async get(uuid, size = 10, page = this.pageBanner, sort = this.sortBanner) {
    this.loadingBanner = true;
    const response = await BranchesAPI.get(uuid, {
      size,
      page,
      sort,
    });
    this.loadingBanner = false;

    if (response.error) {
      this.branch = [];
      return;
    }

    this.branch = new BranchModel({
      ...response,
    });
    this.totalPagesBanner = response.totalPages;
    this.pageBanner = response.number;
    this.sizeBanner = response.size;
  }

  async setSortBanner(uuid, sort) {
    this.sort = sort;
    await this.get(uuid, this.size, this.page, sort);
  }

  async setPageBanner(uuid, page) {
    await this.get(uuid, this.size, page);
  }

  async delete(uuid) {
    this.loading = true;
    const response = await BranchesAPI.delete(uuid);
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Excluído com sucesso!');
    }

    return response;
  }

  setHoursProp(prop, value) {
    const key = prop.split('_')[0];
    const isOpen = prop.includes('start');

    const oldHour = this.branch[key]
      ? this.branch[key].split('as')[isOpen ? 1 : 0].replace(/\s/g, '')
      : '';
    const newHour = `${isOpen ? value : oldHour} as ${
      isOpen ? oldHour : value
    }`;

    if (key === 'sundayOpeningHours') this.branch.holidayOpeningHours = newHour;

    this.branch[key] = newHour;
  }

  onChange(prop, value) {
    switch (prop) {
      case 'file':
        this.branchFile = value;
        break;
      case 'openingHours_start':
      case 'openingHours_end':
      case 'saturdayOpeningHours_start':
      case 'saturdayOpeningHours_end':
      case 'sundayOpeningHours_start':
      case 'sundayOpeningHours_end':
        this.setHoursProp(prop, value);
        break;
      default:
        this.branch[prop] = value;
    }
  }
}

export default BranchesStore;


